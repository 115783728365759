import { createSlice } from "@reduxjs/toolkit";
const initialLeadCicloVendasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  leadCicloVendaForEdit: undefined,
  lastError: null,
  leadCicloVendaPendenteForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const leadCicloVendasSlice = createSlice({
  name: "leadCicloVendas",
  initialState: initialLeadCicloVendasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCicloVendaLeadById
    leadCicloVendaFetched: (state, action) => {
      state.actionsLoading = false;
      state.leadCicloVendaForEdit = action.payload.leadCicloVendaForEdit;
      state.error = null;
    },
    // findLeadCicloVendas
    leadCicloVendasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    leadCicloVendasPendenteFetched: (state, action) => {
      state.actionsLoading = false;
      state.leadCicloVendaPendenteForEdit = action.payload.cicloVendaPendenteLead;
      state.error = null;
    },

    // createCicloVendaLead
    leadCicloVendaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.leadCicloVenda);
    },

  }
});
