import { createSlice } from "@reduxjs/toolkit";
import { aplicarMascaraTelefone, aplicarMascaraCEP } from "../../../../lib/formatarCampos";
const initialLeadsV2State = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    leadV2ForEdit: undefined,
    leadConfig:undefined,
    capacidadeEnergetica: undefined,
    lastError: null,
    updateAgente: null,
    leadFunil: undefined,
    dataFinal: null,
    leadCalculoDadoProposta: {
        consumoTotal: "",
        mesApurado: "",
        gastoTotalApurado: "",
        economiaEstimadaAnual: "",
        percentualEconomia: ""
    },
    leadContrato: null,
    leadProcuracao: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const leadsV2Slice = createSlice({
    name: "leadsV2",
    initialState: initialLeadsV2State,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getLeadV2ById

        leadObterConfiguracao: (state, action) => {
            state.actionsLoading = false;
            state.leadConfig = action.payload.leadConfig;
            state.error = null;

        },
        leadV2Fetched: (state, action) => {


            state.actionsLoading = false;
            // APLICAR A MASCARA
            state.leadV2ForEdit = action.payload.leadV2ForEdit;
            state.error = null;

        },
        // findLeadsV2
        leadsV2Fetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createLeadV2
        leadV2Created: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities = [];
            state.leadV2ForEdit = action.payload.leadV2;
        },
        leadMemoriaCalculoCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.leadCalculoDadoProposta = action.payload.leadMemoriaCalculo;
        },
        leadGetContrato: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.leadContrato = action.payload.leadContrato;
        },
        leadDadoPropostaCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.leadCalculoDadoProposta = action.payload.leadMemoriaCalculo;
        },
        leadV2updateAgente: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.updateAgente = action;
        },
        leadV2HoraFinal: (state, action) => {
            // state.actionsLoading = false;
            // state.error = null;
            // state.updateAgente = action;
            state.dataInicio = action.payload.dataInicio;
        },
        // updateLeadV2
        leadV2Updated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.entities = state.entities.map(entity => {
            //     if (entity.id === action.payload.leadV2.id) {
            //         return action.payload.leadV2;
            //     }
            //     return entity;
            // });
        },
        // deleteLeadV2
        leadV2Deleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteLeadsV2
        leadsV2Deleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.id.includes(el.id)
            );
        },
        capacidadeEnergetica: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.capacidadeEnergetica = action.payload.Grafico
        },
        // leadsV2UpdateState
        leadsV2StatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        funilLeadFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.leadV2);
            state.leadFunil = action.payload.Funil.entities;
        },
        leadGetProcuracao: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.leadProcuracao = action.payload.leadProcuracao;
        },
    }
});
