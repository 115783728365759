import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { useField } from "formik";

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  var layout = localStorage.getItem('@layout');
  var logo = localStorage.getItem('@logo');

  var element = document.querySelector('.header');
  var bomfuturo32 = document.getElementById('bomfuturo32');
  var headerID = document.getElementById('kt_header');

  var bomfuturo192 = document.getElementById('bomfuturo192');

  var rzk32 = document.getElementById('rzk32');
  var rzk192 = document.getElementById('rzk192');
  var rzkAppleTouch = document.getElementById('rzkAppleTouch');

  var magpay32 = document.getElementById('magpay32');
  var magpay192 = document.getElementById('magpay192');

  var bolt32 = document.getElementById('bolt32');
  var bolt192 = document.getElementById('bolt192');


  rzk32.rel = "icon";
  rzk32.href = "";
  rzk32.sizes = "32x32";
  rzk192.rel = "icon";
  rzk192.href = "";
  rzk192.sizes = "192x192";
  rzkAppleTouch.rel = "apple-touch-icon";
  rzkAppleTouch.href = "";

  useEffect(() => {
    window.onload = function () {

      // console.log(window.location.href);
      if (layout == 'L2' && logo == 'bomfuturo' && bomfuturo32 != null && bomfuturo192 != null) {
        bomfuturo32.rel = "icon";
        bomfuturo32.href = "/media/logos/mato-grosso3.png";
        bomfuturo32.sizes = "32x32";
        bomfuturo192.rel = "icon";
        bomfuturo192.href = "/media/logos/mato-grosso3.png";
        bomfuturo192.sizes = "192x192";
        document.querySelector('.header').style.backgroundColor = '#259f36';
        document.querySelector("html > head > title").innerHTML = "Mato Grosso Energia Renovável";
      } else if (layout == 'L1' && logo == 'rzk' && rzk32 != null && rzk192 != null) {
        rzk32.rel = "icon";
        rzk32.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon-100x100.png";
        rzk32.sizes = "32x32";
        rzk192.rel = "icon";
        rzk192.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
        rzk192.sizes = "192x192";
        rzkAppleTouch.rel = "apple-touch-icon";
        rzkAppleTouch.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
        document.querySelector('.header').style.backgroundColor = '#0aa979';
        document.querySelector("html > head > title").innerHTML = "RZK Energia";
      } else if (layout == 'L3' && logo == 'magpay' && magpay32 != null && magpay192 != null) {
        magpay32.rel = "icon";
        magpay32.href = "/media/logos/logo_magpay.png";
        magpay32.sizes = "32x32";
        magpay192.rel = "icon";
        magpay192.href = "/media/logos/logo_magpay.png";
        magpay192.sizes = "192x192";
        document.querySelector('.header').style.backgroundColor = '#66CC00';
        document.querySelector("html > head > title").innerHTML = "MAGPAY";
      } else if (layout == 'L4' && logo == 'bolt' && bolt32 != null && bolt192 != null) {
        bolt32.rel = "icon";
        bolt32.href = "/media/logos/bolt-icon.png";
        bolt32.sizes = "32x32";
        bolt192.rel = "icon";
        bolt192.href = "/media/logos/bolt-icon.png";
        bolt192.sizes = "192x192";
        document.querySelector('.header').style.backgroundColor = 'rgba(0,0,0,.75)';
        document.querySelector("html > head > title").innerHTML = "Bolt";
      }
    }

  }, [window.onload])


  if (document.querySelector('.header') != null) {
    if (layout == 'L2' && logo == 'bomfuturo' && element != null && bomfuturo32 != null && bomfuturo192 != null) {
      bomfuturo32.rel = "icon";
      bomfuturo32.href = "/media/logos/mato-grosso3.png";
      bomfuturo32.sizes = "32x32";
      bomfuturo192.rel = "icon";
      bomfuturo192.href = "/media/logos/mato-grosso3.png";
      bomfuturo192.sizes = "192x192";
      document.querySelector('.header').style.backgroundColor = '#259f36';
      document.querySelector("html > head > title").innerHTML = "Mato Grosso Energia Renovável";
    } else if (layout == 'L1' && logo == 'rzk' && element != null && rzk32 != null && rzk192 != null) {
      rzk32.rel = "icon";
      rzk32.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon-100x100.png";
      rzk32.sizes = "32x32";
      rzk192.rel = "icon";
      rzk192.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
      rzk192.sizes = "192x192";
      rzkAppleTouch.rel = "apple-touch-icon";
      rzkAppleTouch.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
      document.querySelector('.header').style.backgroundColor = '#0aa979';
      document.querySelector("html > head > title").innerHTML = "RZK Energia";
    } else if (layout == 'L3' && logo == 'magpay' && magpay32 != null && magpay192 != null) {
      magpay32.rel = "icon";
      magpay32.href = "/media/logos/logo_magpay.png";
      magpay32.sizes = "32x32";
      magpay192.rel = "icon";
      magpay192.href = "/media/logos/logo_magpay.png";
      magpay192.sizes = "192x192";
      document.querySelector('.header').style.backgroundColor = '#66CC00';
      document.querySelector("html > head > title").innerHTML = "MAGPAY";
    } else if (layout == 'L4' && logo == 'bolt' && bolt32 != null && bolt192 != null) {
      bolt32.rel = "icon";
      bolt32.href = "/media/logos/bolt-icon.png";
      bolt32.sizes = "32x32";
      bolt192.rel = "icon";
      bolt192.href = "/media/logos/bolt-icon.png";
      bolt192.sizes = "192x192";
      document.querySelector('.header').style.backgroundColor = 'rgba(0,0,0,.75)';
      document.querySelector("html > head > title").innerHTML = "Bolt";
    }
  }

  const definirLogo = () => {
    if (layout == 'L2' && logo == 'bomfuturo') {
      return toAbsoluteUrl("/media/logos/mato-grosso2.png")
    } else if (layout == 'L1' && logo == 'rzk') {
      return toAbsoluteUrl("/media/logos/logo-letter-9.png")
    } else if (layout == 'L3' && logo == 'magpay') {
      return toAbsoluteUrl("/media/logos/magpay-plataforma.png")
    } else if(layout == "L4" && logo == "bolt"){
      return toAbsoluteUrl("/media/logos/logo-bolt.png")
    }
    // layout == 'L2' && logo == 'bomfuturo' ?  : 
  }


  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          {/* begin::Left */}
          <div className="d-flex align-items-stretch mr-3">
            {/* begin::Header Logo */}
            <div className="header-logo">
              <Link to="/">
                <img
                  className="logo-default max-h-40px"
                  alt="Logo"
                  src={definirLogo()}
                  // src={'/media/logos/logo-bolt.png'}
                // layout == 'L2' && logo == 'bomfuturo' ? toAbsoluteUrl("/media/logos/mato-grosso2.png") : toAbsoluteUrl("/media/logos/logo-letter-9.png")
                // src={toAbsoluteUrl("/media/logos/logo-letter-9.png")}
                // src={toAbsoluteUrl("/media/logos/mato-grosso2.png")}
                />
                <img
                  className="logo-sticky max-h-40px"
                  alt="Logo"
                  src={definirLogo()}
                  // src={'/media/logos/logo-bolt.png'}
                // layout == 'L2' && logo == 'bomfuturo' ? toAbsoluteUrl("/media/logos/mato-grosso2.png") : toAbsoluteUrl("/media/logos/logo-letter-9.png")
                // src={toAbsoluteUrl("/media/logos/logo-letter-9.png")}
                // src={toAbsoluteUrl("/media/logos/mato-grosso2.png")}
                />
              </Link>
            </div>
            {/* end::Header Logo */}
            {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          </div>
          {/* end::Left */}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
