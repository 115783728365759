import {createSlice} from "@reduxjs/toolkit";

const initialGeracaoUsinasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: {custo:{consumo:0, diasMedidos:0, energiaCompensada:null,totalGD:null,totalSGD:null,economia:null, economia:null,energiaGerada:0, numeroUCs:0, locacaoTerra:0, locacaoEquipamento:0, om:0, faturamentoUCs:0, demandaGeracao:0},consumo:[]},
  geracaoUsinaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const geracaoUsinaSlice = createSlice({
  name: "geracaoUsina",
  initialState: initialGeracaoUsinasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findGeracaoUsinas
    geracaoUsinaFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    }
  }
});
