import {createSlice} from "@reduxjs/toolkit";

const initialMonitoramentoOnlinesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: undefined,
  monitoramentoOnlineForEdit: undefined,
  lastError: null,
  graficoDiario:null,
  graficoDiarioMes:null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const monitoramentoOnlineSlice = createSlice({
  name: "monitoramentoOnlines",
  initialState: initialMonitoramentoOnlinesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findMonitoramentoOnlines
    monitoramentoOnlineFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },

    monitoramentoOnlineGraficoFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.graficoDiario = entities;
    },
    
    monitoramentoOnlineGraficoMesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.graficoDiarioMes = entities;
    },
  }
});
