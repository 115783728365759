import { createSlice } from "@reduxjs/toolkit";

const initialProcessoRpasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  processoRpaForEdit: undefined,
  lastError: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const processoRpasSlice = createSlice({
  name: 'processoRpas',
  initialState: initialProcessoRpasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    processoRpaFetched: (state, action) => {
      state.actionsLoading = false;
      state.processoRpaForEdit = action.payload.processoRpaForEdit;
      state.error = null;
    },
    processoRpasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    processoRpaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // console.log(action);
      state.entities.push(action.payload.processoRpa);
    },
    processoRpaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.idProcessoRpa === action.payload.processoRpa.idProcessoRpa) {
          return action.payload.processoRpa;
        }
        return entity;
      });
    },
    processoRpaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id === action.payload.id);
    },
    processoRpasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
