
import React from "react";


export function ModalidadeTarifariaFormatacao(
    cellContent,
    row,
    rowIndex
) {
    const tamanho = 60;
    return (
        <>
            {cellContent && cellContent.value == "az" &&
                (<>
                    <text >Azul</text>
                </>)
            }
            {cellContent && cellContent.value == "ve" &&
                (<>
                    <text >Verde</text>
                </>)
            }
            {cellContent && cellContent.value == "ir" &&
                (<>
                    <text >Irrigantes</text>
                </>)
            }
            {cellContent && cellContent.value == "ou" &&
                (<>
                    <text >Outras</text>
                </>)
            }


        </>
    );
}
