import React from "react";
import {useField, useFormikContext} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function Preco({
                          field, // { name, value, onChange, onBlur }
                          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          label,
                          withFeedbackLabel = true,
                          customFeedbackLabel,
                          type = "text",
                          ...props
                      }) {

    return (
        <>
            {label && <label>{label}</label>}
            <NumberFormat thousandSeparator={true} prefix={'R$'}
                          decimalScale={2}
                          decimalSeparator=","
                          fixedDecimalScale
                          thousandSeparator="."
                          value={field.value}
                          onValueChange={val => {
                              form.setFieldValue(field.name, val.floatValue);
                          }}
                          className={getFieldCSSClasses(form.touched[field.name], form.errors[field.name])}
                          {...props}
            />
            {withFeedbackLabel &&  form.errors[field.name] && (
                <FieldFeedbackLabel
                    error={form.errors[field.name]}
                    touched={form.touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}

export function PrecoFormularioBomFuturo({
    field, // { name, value, onChange, onBlur }
    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    ...props
}) {

return (
<>
{label && <label>{label}</label>}
<NumberFormat thousandSeparator={true} prefix={'R$'}
    decimalScale={6}
    decimalSeparator=","
    fixedDecimalScale
    thousandSeparator="."
    value={field.value}
    onValueChange={val => {
        form.setFieldValue(field.name, val.floatValue);
    }}
    className={getFieldCSSClasses(form.touched[field.name], form.errors[field.name])}
    {...props}
/>
{withFeedbackLabel &&  form.errors[field.name] && (
<FieldFeedbackLabel
error={form.errors[field.name]}
touched={form.touched[field.name]}
label={label}
type={type}
customFeedbackLabel={customFeedbackLabel}
/>
)}
</>
);
}
