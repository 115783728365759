import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialContratosUploadState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  contratoUploadForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const contratosUploadSlice = createSlice({
  name: "contratosUpload",
  initialState: initialContratosUploadState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getContratoUploadById
    contratoUploadFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      if (action.payload.contratoUploadForEdit && action.payload.contratoUploadForEdit.idContratoUpload) {
        action.payload.contratoUploadForEdit.endCEPContratoUpload = aplicarMascaraCEP(action.payload.contratoUploadForEdit.endCEPContratoUpload);
      }
      state.contratoUploadForEdit = action.payload.contratoUploadForEdit;
      state.error = null;
    },
    // findContratosUpload
    contratosUploadFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createContratoUpload
    contratoUploadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.contratoUpload);
    },
    // updateContratoUpload
    contratoUploadUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.contratoUpload.id) {
          return action.payload.contratoUpload;
        }
        return entity;
      });
    },
    // deleteContratoUpload
    contratoUploadDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteContratosUpload
    contratosUploadDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // contratosUploadUpdateState
    contratosUploadStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
