import React, { useEffect, useState } from "react";


export function ButtonPerfilAcesso({
    onClick,
    className,
    children,
    idPermissaoAcesso,
    validar,
    type
}) {

    const [botaoHabilitado, setBotaoHabilitado] = useState(false);
    var objetoArmazenado = localStorage.getItem('permissoes');

    function verificaInclusaoNoArray(arrayDeNumeros, numero) {
        if (arrayDeNumeros.includes(numero)) {
            return true;
        } else {
            return false;
        }
    }

    function extrairNumerosDaString(string) {
        const numerosEncontrados = string.match(/\d+/g);
        if (numerosEncontrados) {
            const numerosInteiros = numerosEncontrados.map(numero => parseInt(numero));
            return numerosInteiros;
        } else {
            return [];
        }
    }


    useEffect(() => {

        if (validar == "false") {
            setBotaoHabilitado(false);
        } else {
            if (objetoArmazenado !== null && objetoArmazenado !== undefined && objetoArmazenado !== "undefined") {
                const array1 = extrairNumerosDaString(objetoArmazenado);

                if (array1.length > 0) {
                    if (!verificaInclusaoNoArray(array1, idPermissaoAcesso)) {
                        // console.log("TRUE", array1, idPermissaoAcesso)
                        setBotaoHabilitado(true);
                    } else {
                        // console.log("FALSE", array1, idPermissaoAcesso)
                        setBotaoHabilitado(false);
                    }
                } else {
                    // console.log("Nenhum número encontrado na string.");
                    setBotaoHabilitado(true);
                }
                // console.log('Objeto recuperado:', objetoRecuperado);
            } else {
                setBotaoHabilitado(true);
                // console.log('Objeto não encontrado no localStorage');
            }
        }
    }, [validar, objetoArmazenado]);

    return (
        <>
            <button
                type={type}
                className={className}
                onClick={onClick}
                disabled={botaoHabilitado}
            >
                {/* <span className="h-4" /> */}
                {children}
            </button>

        </>
    );
}
