import { createSlice } from "@reduxjs/toolkit";
import { nullFormat } from "numeral";

const initialInformacaoFaturasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  informacaoFaturaForEdit: undefined,
  lastError: null,
  graficoUltimos12Meses: null,
  detalheConsorcioGCUCS: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const informacaoFaturasSlice = createSlice({
  name: "informacaoFaturas",
  initialState: initialInformacaoFaturasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getInformacaoFaturaById
    informacaoFaturaFetched: (state, action) => {
      state.actionsLoading = false;
      state.informacaoFaturaForEdit = action.payload.informacaoFaturaForEdit;
      state.error = null;
    },
    // findInformacaoFaturas
    informacaoFaturasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.graficoUltimos12Meses = null;
      state.detalheConsorcioGCUCS = null;
    },
    graficoUltimos12MesesFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.graficoUltimos12Meses = action.payload.graficoUltimos12Meses;
    },
    fetchDetalheConsorcioGCUCSMesesFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.detalheConsorcioGCUCS = action.payload.detalheConsorcioGCUCS;
    },
    // createInformacaoFatura
    informacaoFaturaCreated: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      // state.entities.push(action.payload.informacaoFatura);
    },
    // updateInformacaoFatura
    informacaoFaturaUpdated: (state, action) => {
      if (action.payload.funcaoStatus == 1) {
        state.error = null;
        state.actionsLoading = false;
      } else {
        state.error = null;
        state.actionsLoading = false;
        state.entities = state.entities.map(entity => {
          if (entity.id === action.payload.rateioItem.id) {
            return action.payload.rateioItem;
          }
          return entity;
        });
      }
    },
    // deleteInformacaoFatura
    informacaoFaturaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteInformacaoFaturas
    informacaoFaturasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // informacaoFaturasUpdateState
    informacaoFaturasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
