import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_layout/layout";

import {MyPage} from "./pages/MyPage";


import { GdsPage } from "./modules/GD/pages/gestaodistribuida/GdsPage";
const GDPage = lazy(() =>
  import("./modules/GD/pages/gdPage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={GdsPage}/>

                <ContentRoute exact path="/my-page" component={MyPage}/>

                <Route path="/gd" component={GDPage}/>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
