import { createSlice } from "@reduxjs/toolkit";
import { aplicarMascaraCEP } from "../../../../lib/formatarCampos";
const initialUcsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  ucForEdit: undefined,
  lastError: null,
  ucExiste: null,

};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ucsSlice = createSlice({
  name: "ucs",
  initialState: initialUcsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUcById
    ucFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      if (action.payload.ucForEdit && action.payload.ucForEdit.idUC) {
        action.payload.ucForEdit.endCEPUC = aplicarMascaraCEP(action.payload.ucForEdit.endCEPUC);
      }
      state.ucForEdit = action.payload.ucForEdit;
      state.error = null;
      state.ucExiste = null;
    },
    // findUcs
    ucsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.ucExiste = null;
    },
    // createUc
    ucCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.ucExiste = action.payload.uc;
    },
    // updateUc
    ucUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.ucExiste = action.payload.uc;
    },
    // deleteUc
    ucDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.ucExiste = null;
    },
    // deleteUcs
    ucsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.ucExiste = null;
    }
  }
});
