import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialTiposProdutoState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  tipoProdutoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const tiposProdutoSlice = createSlice({
  name: "tiposProduto",
  initialState: initialTiposProdutoState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTipoProdutoById
    tipoProdutoFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      if (action.payload.tipoProdutoForEdit && action.payload.tipoProdutoForEdit.idTipoProduto) {
        action.payload.tipoProdutoForEdit.endCEPTipoProduto = aplicarMascaraCEP(action.payload.tipoProdutoForEdit.endCEPTipoProduto);
      }
      state.tipoProdutoForEdit = action.payload.tipoProdutoForEdit;
      state.error = null;
    },
    // findTiposProduto
    tiposProdutoFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTipoProduto
    tipoProdutoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.tipoProduto);
    },
    // updateTipoProduto
    tipoProdutoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.tipoProduto.idTipoProduto) {
          return action.payload.tipoProduto;
        }
        return entity;
      });
    },
    // deleteTipoProduto
    tipoProdutoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTiposProduto
    tiposProdutoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   el => !action.payload.ids.includes(el.id)
      // );
    },
    // tiposProdutoUpdateState
    tiposProdutoStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
