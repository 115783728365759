import * as requestFromServer from "./administrativosCrud";
import {administrativosSlice, callTypes} from "./administrativosSlice";

const {actions} = administrativosSlice;

export const fetchAdministrativos = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.administrativosFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findAdministrativos(queryParams, tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.administrativosFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find administrativos";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAdministrativo = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.administrativoFetched({ administrativoForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAdministrativoById(id)
    .then(response => {
        const administrativo = response.data.entities.resultado;
      dispatch(
        actions.administrativoFetched({ administrativoForEdit: administrativo })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find administrativo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAdministrativo = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdministrativo(id)
    .then(response => {
      dispatch(actions.administrativoDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete administrativo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createAdministrativo = administrativoForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAdministrativo(administrativoForCreation)
    .then(response => {
        const administrativo = response.data.entities;
      dispatch(actions.administrativoCreated({ administrativo:administrativo }));
    })
    .catch(error => {
      error.clientMessage = "Can't create administrativo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateAdministrativo = (id,administrativo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAdministrativo(id,administrativo)
    .then(() => {
      dispatch(actions.administrativoUpdated({ administrativo }));
    })
    .catch(error => {
      error.clientMessage = "Can't update administrativo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAdministrativos = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdministrativos(ids)
    .then(() => {
      dispatch(actions.administrativosDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete administrativos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaAdministrativos = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaAdministrativos(ids)
      .then(() => {
        dispatch(actions.administrativosDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete administrativos";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const marcarComoLidaAdministrativo= (tipoNotificacaoMenu) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaAdministrativo(tipoNotificacaoMenu)
      // .then(() => {
      //   dispatch(actions.gestaoFaturasDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
