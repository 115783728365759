import {createSlice} from "@reduxjs/toolkit";

const initialConfiguracaoLeadCadastrosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  configuracaoLeadCadastroForEdit: undefined,
  lastError: null,
  configuracaoLeadCadastroEtapa: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const configuracaoLeadCadastrosSlice = createSlice({
  name: "configuracaoLeadCadastros",
  initialState: initialConfiguracaoLeadCadastrosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getConfiguracaoLeadCadastroById
    configuracaoLeadCadastroFetched: (state, action) => {
      state.actionsLoading = false;
      state.configuracaoLeadCadastroForEdit = action.payload.configuracaoLeadCadastroForEdit;
      // configuracaoLeadCadastroEtapa = undefined
      state.error = null;
    },
    configuracaoLeadCadastroEtapaFetched: (state, action) => {
      state.actionsLoading = false;
      state.configuracaoLeadCadastroEtapa = action.payload.configuracaoLeadCadastroEtapa;
      state.error = null;
    },
    // findConfiguracaoLeadCadastros
    configuracaoLeadCadastrosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createConfiguracaoLeadCadastro
    configuracaoLeadCadastroCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.configuracaoLeadCadastro);
    },
    // updateConfiguracaoLeadCadastro
    configuracaoLeadCadastroUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.idConfiguracaoLeadCadastro === action.payload.configuracaoLeadCadastro.idConfiguracaoLeadCadastro) {
      //     return action.payload.configuracaoLeadCadastro;
      //   }
      //   return entity;
      // });
    },
    // deleteConfiguracaoLeadCadastro
    configuracaoLeadCadastroDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteConfiguracaoLeadCadastros
    configuracaoLeadCadastrosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // configuracaoLeadCadastrosUpdateState
    configuracaoLeadCadastrosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    configuracaoLeadCadastroEtapaFetched: (state, action) => {
      state.actionsLoading = false;
      state.configuracaoLeadCadastroEtapa = action.payload.configuracaoLeadCadastroEtapa;
      state.error = null;
    }
  }
});
