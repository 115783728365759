import React, { useMemo, useEffect, useInsertionEffect } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
    };
  }, [uiService]);

  var layout = localStorage.getItem('@layout');
  var logo = localStorage.getItem('@logo');

  var element = document.querySelector('.header');
  var bomfuturo32 = document.getElementById('bomfuturo32');
  var headerID = document.getElementById('kt_header');

  var bomfuturo192 = document.getElementById('bomfuturo192');

  var rzk32 = document.getElementById('rzk32');
  var rzk192 = document.getElementById('rzk192');
  var rzkAppleTouch = document.getElementById('rzkAppleTouch');

  var magpay32 = document.getElementById('magpay32');
  var magpay192 = document.getElementById('magpay192');

  var bolt32 = document.getElementById('bolt32');
  var bolt192 = document.getElementById('bolt192');

  useEffect(() => {

    document.addEventListener("DOMContentLoaded", function (event) {
      var header = document.querySelector(".header-mobile");

      if (layout == 'L2' && logo == 'bomfuturo') {
        header.style.backgroundColor = '#259f36';
      } else if (layout == 'L1' && logo == 'rzk') {
        header.style.backgroundColor = '#0aa979';
      } else if (layout == 'L3' && logo == 'magpay') {
        header.style.backgroundColor = '#66CC00';
      } else if (layout == 'L4' && logo == 'bolt') {
        header.style.backgroundColor = 'rgba(0,0,0,.75)';
      }
    });

    window.onload = function () {

      if (layout == 'L2' && logo == 'bomfuturo' && bomfuturo32 != null && bomfuturo192 != null) {
        bomfuturo32.rel = "icon";
        bomfuturo32.href = "/media/logos/mato-grosso3.png";
        bomfuturo32.sizes = "32x32";
        bomfuturo192.rel = "icon";
        bomfuturo192.href = "/media/logos/mato-grosso3.png";
        bomfuturo192.sizes = "192x192";
        document.querySelector('.header-mobile ').style.backgroundColor = '#259f36';
        document.querySelector("html > head > title").innerHTML = "Mato Grosso Energia Renovável";
      } else if (layout == 'L1' && logo == 'rzk' && rzk32 != null && rzk192 != null) {
        rzk32.rel = "icon";
        rzk32.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon-100x100.png";
        rzk32.sizes = "32x32";
        rzk192.rel = "icon";
        rzk192.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
        rzk192.sizes = "192x192";
        rzkAppleTouch.rel = "apple-touch-icon";
        rzkAppleTouch.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
        document.querySelector('.header-mobile ').style.backgroundColor = '#0aa979';
        document.querySelector("html > head > title").innerHTML = "RZK Energia";
      } else if (layout == 'L3' && logo == 'magpay' && magpay32 != null && magpay192 != null) {
        magpay32.rel = "icon";
        magpay32.href = "/media/logos/logo_magpay.png";
        magpay32.sizes = "32x32";
        magpay192.rel = "icon";
        magpay192.href = "/media/logos/logo_magpay.png";
        magpay192.sizes = "192x192";
        document.querySelector('.header-mobile ').style.backgroundColor = '#66CC00';
        document.querySelector("html > head > title").innerHTML = "MAGPAY";
      } else if (layout == 'L4' && logo == 'bolt' && bolt32 != null && bolt192 != null) {
        bolt32.rel = "icon";
        bolt32.href = "/media/logos/bolt-icon.png";
        bolt32.sizes = "32x32";
        bolt192.rel = "icon";
        bolt192.href = "/media/logos/bolt-icon.png";
        bolt192.sizes = "192x192";
        document.querySelector('.header').style.backgroundColor = 'rgba(0,0,0,.75)';
        document.querySelector("html > head > title").innerHTML = "Bolt";
      }
    }

  }, [window.onload]);

  

  if (document.querySelector('.header-mobile ') != null) {
    if (layout == 'L2' && logo == 'bomfuturo' && element != null && bomfuturo32 != null && bomfuturo192 != null) {
      bomfuturo32.rel = "icon";
      bomfuturo32.href = "/media/logos/mato-grosso3.png";
      bomfuturo32.sizes = "32x32";
      bomfuturo192.rel = "icon";
      bomfuturo192.href = "/media/logos/mato-grosso3.png";
      bomfuturo192.sizes = "192x192";
      document.querySelector('.header-mobile ').style.backgroundColor = '#259f36';
      document.querySelector("html > head > title").innerHTML = "Mato Grosso Energia Renovável";
    } else if (layout == 'L1' && logo == 'rzk' && element != null && rzk32 != null && rzk192 != null) {
      rzk32.rel = "icon";
      rzk32.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon-100x100.png";
      rzk32.sizes = "32x32";
      rzk192.rel = "icon";
      rzk192.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
      rzk192.sizes = "192x192";
      rzkAppleTouch.rel = "apple-touch-icon";
      rzkAppleTouch.href = "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
      document.querySelector('.header-mobile ').style.backgroundColor = '#0aa979';
      document.querySelector("html > head > title").innerHTML = "RZK Energia";
    } else if (layout == 'L3' && logo == 'magpay' && magpay32 != null && magpay192 != null) {
      magpay32.rel = "icon";
      magpay32.href = "/media/logos/logo_magpay.png";
      magpay32.sizes = "32x32";
      magpay192.rel = "icon";
      magpay192.href = "/media/logos/logo_magpay.png";
      magpay192.sizes = "192x192";
      document.querySelector('.header-mobile ').style.backgroundColor = '#66CC00';
      document.querySelector("html > head > title").innerHTML = "MAGPAY";
    } else if (layout == 'L4' && logo == 'bolt' && bolt32 != null && bolt192 != null) {
      bolt32.rel = "icon";
      bolt32.href = "/media/logos/bolt-icon.png";
      bolt32.sizes = "32x32";
      bolt192.rel = "icon";
      bolt192.href = "/media/logos/bolt-icon.png";
      bolt192.sizes = "192x192";
      document.querySelector('.header').style.backgroundColor = 'rgba(0,0,0,.75)';
      document.querySelector("html > head > title").innerHTML = "Bolt";
    }
  }
    const definirLogo = () => {
      if (layout == 'L2' && logo == 'bomfuturo') {
        return toAbsoluteUrl("/media/logos/mato-grosso2.png")
      } else if (layout == 'L1' && logo == 'rzk') {
        return toAbsoluteUrl("/media/logos/logo-letter-9.png")
      } else if (layout == 'L3' && logo == 'magpay') {
        return toAbsoluteUrl("/media/logos/magpay-plataforma.png")
      }  else if(layout == "L4" && logo == "bolt"){
        return toAbsoluteUrl("/media/logos/logo-bolt.png")
      }
      // layout == 'L2' && logo == 'bomfuturo' ?  : 
    }

    return (
      <>
        {/*begin::Header Mobile*/}
        <div
          id="kt_header_mobile"
          className={`header-mobile ${layoutProps.headerMobileCssClasses}`}
          {...layoutProps.headerMobileAttributes}
        >
          {/* begin::Logo */}
          <Link to="/">
            <img
              alt="Logo"
              className="logo-default max-h-30px"
              // src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
              src={definirLogo()}
            // layout == 'L2' && logo == 'bomfuturo' ? toAbsoluteUrl("/media/logos/mato-grosso2.png") : toAbsoluteUrl("/media/logos/logo-letter-9.png")  
            />
          </Link>
          {/* end::Logo */}

          {/* begin::Toolbar */}
          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
              <button
                className="btn p-0 burger-icon burger-icon-left"
                id="kt_aside_mobile_toggle"
              >
                <span />
              </button>
            )}

            {layoutProps.headerMenuSelfDisplay && (
              <button
                className="btn p-0 burger-icon burger-icon-left ml-4"
                id="kt_header_mobile_toggle"
              >
                <span />
              </button>
            )}

            <button
              className="btn btn-icon btn-hover-transparent-white p-0 ml-3"
              id="kt_header_mobile_topbar_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </button>
          </div>
          {/* end::Toolbar */}
        </div>
        {/* end::Header Mobile */} {/*end::Header Mobile*/}
      </>
    );
  }
