import { createSlice } from "@reduxjs/toolkit";

const initialGdsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  gdForEdit: undefined,
  gdForEditFiltro: undefined,
  lastError: null,
  ucRateioImportacaoID: undefined,
  erroImportar: null,
  ucRateiosNumeroForEdit: undefined,
  carregar: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const gdsSlice = createSlice({
  name: "gds",
  initialState: initialGdsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getGdById
    gdFetched: (state, action) => {
      state.actionsLoading = false;
      state.gdForEdit = action.payload.gdForEdit;
      state.error = null;
    },
    gdRateiosImportado: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.ucRateioImportacaoID = action.payload.ucRateios;
      state.carregar = true;
    },
    gdDataFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.gdForEditFiltro = action.payload.gdForEditFiltro;
      state.error = null;
    },
    // findGds
    gdsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.gdForEditFiltro = undefined;
    },
    // createGd
    gdCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.gd);
    },
    // updateGd
    gdUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.gd.id) {
          return action.payload.gd;
        }
        return entity;
      });
    },
    // deleteGd
    gdDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteGds
    gdsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // gdsUpdateState
    gdsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    ucRateiosRodar: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.erroImportar = action.payload.erroImportar;
      state.carregar = false;
    },
    ucRateiosNumerosFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateiosNumeroForEdit = action.payload.ucRateiosNumeroForEdit;
      state.error = null;
    },
  }
});
