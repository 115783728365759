import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialSuperListasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  superListaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const superListasSlice = createSlice({
  name: "superListas",
  initialState: initialSuperListasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSuperListaById
    superListaFetched: (state, action) => {


      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.superListaForEdit = action.payload.superListaForEdit;
      state.error = null;

    },
    // findSuperListas
    superListasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSuperLista
    superListaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.superLista);
    },
    // updateSuperLista
    superListaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.superLista.id) {
          return action.payload.superLista;
        }
        return entity;
      });
    },
    // deleteSuperLista
    superListaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteSuperListas
    superListasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // superListasUpdateState
    superListasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
