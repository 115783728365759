import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new gds to the server

export function createGD(gd) {
  gd.idDistribuidora = parseInt(gd.idDistribuidora);
  gd.idCliente = parseInt(gd.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:gd,
  };

  return axios(request);

}
// READ
// Server should return filtered gds by productId
export function getAllProductGDSByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getGDById(gdId) {
  return axios.get(`${SPECIFICATIONS_URL}/${gdId}`);
}

// Server should return sorted/filtered gds and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/gdsfind/{productId}'. Should be 'api/gds/find/{productId}'!!!
export function findGDS(queryParams, todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "1";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the gd
export function updateGD(id,gd) {
  gd.IdGD =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: gd,
  };
  return axios(request);
}

// DELETE => delete the gd
export function deleteGD(gdId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${gdId}`);
}

export function deleteGDS(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaGDS(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}

export function marcarComoLidaGDS(tipoNotificacaoMenu, todas) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas:  todas },
  };

  return axios(request);
}
