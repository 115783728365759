import {createSlice} from "@reduxjs/toolkit";

const initialUCRateiosGDState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  ucRateiosGDForEdit: undefined,
  lastError: null,
  ucRateiosGDNumeroForEdit:undefined,
  ucRateioImportacaoID:undefined,
  ucRateiosGDNovos: null,

  totalCountUC: 0,
  listLoadingUC: false,
  ucRateioGDTimeLine: null,
  erroImportar: null,
  carregar: false
};
export const callTypes = {
  list: "list",
  action: "action",
};


export const ucRateiosGDSlice = createSlice({
  name: "ucRateiosGD",
  initialState: initialUCRateiosGDState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUCRateioById
    ucRateiosGDFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateiosGDForEdit = action.payload.ucRateiosGDForEdit;
      state.error = null;
    },
    ucRateiosGDTimeLineFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateioGDTimeLine = action.payload.ucRateioGDTimeLine;
      state.error = null;
    },
    ucRateiosGDNumerosFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateiosGDNumeroForEdit = action.payload.ucRateiosGDNumeroForEdit;
      state.error = null;
    },
    // findUCRateiosGD
    ucRateiosGDFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.carregar = false;
    },
    ucRateiosGDNovosFetched: (state, action) => {
      console.log(action.payload)
      // const { totalCount, entities } = action.payload;
      state.listLoadingUC = false;
      state.error = null;
      state.ucRateiosGDNovos = action.payload.ucRateiosGDNovos;
      // state.totalCountUC = totalCount;
    },
    // createUCRateio
    ucRateiosGDCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.ucRateiosGD);
    },
    ucRateiosGDRodar: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.erroImportar = action.payload.erroImportar;
      state.carregar = false;
      // state.ucRateioImportacaoID = undefined;
      // state.entities.push(action.payload.ucRateiosGD);
    },
    ucRateiosGDImportado: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.ucRateioImportacaoID = action.payload.ucRateiosGD;
      state.carregar = true;
    },
    ucRateiosGDImportadoProcessar: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.carregar = true;
      // state.ucRateioImportacaoID = action.payload.ucRateiosGD;
    },
    // updateUCRateio
    ucRateiosGDUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.rateioItem.id) {
          return action.payload.rateioItem;
        }
        return entity;
      });
    },
    ucRateiosGDNovaUCUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.ucRateiosGDNovos = state.ucRateiosGDNovos.map(entity => {
      //   if (entity.idImportacaoGDRateioNumeros === action.payload.ucRateiosGD.idImportacaoGDRateioNumeros) {
      //     return action.payload.ucRateiosGD;
      //   }
      //   return entity;
      // });
    },
    // deleteUCRateio
    ucRateiosGDDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUCRateiosGD
    ucRateiosGDGDDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
