import axios from "axios";
import {config} from "./../../../Config";
export const LOGIN_URL = config.url.apiEndPoint+"/v4/auth/login";
export const LOGIN_URL1 = "api/auth/login";
export const REQUEST_PASSWORD_URL = config.url.apiEndPoint+"/auth/esqueciSenha";

export const ME_URL = config.url.apiEndPoint+"/auth/me";


export function login(email, password) {
  const request = {
    method: 'POST',
    url: LOGIN_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    data:{ "usuario":email, "senha":password },
  };

  return axios(request);
}
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { Usuario:email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}




