import { createSlice } from "@reduxjs/toolkit";

const initialUCRateiosConsorciosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  ucRateiosConsorcioForEdit: undefined,
  lastError: null,
  ucRateiosConsorcioNumeroForEdit: undefined,
  ucRateioImportacaoID: undefined,
  ucRateiosConsorciosNovos: null,
  ucRateiosConsorciosDuplicadoUC: null,

  totalCountUC: 0,
  listLoadingUC: false,
  ucRateioConsorcioTimeLine: null,
  erroImportar: false,
  carregar: false
};
export const callTypes = {
  list: "list",
  action: "action",
};


export const ucRateiosConsorciosSlice = createSlice({
  name: "ucRateiosConsorcios",
  initialState: initialUCRateiosConsorciosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUCRateioById
    ucRateiosConsorcioFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateiosConsorcioForEdit = action.payload.ucRateiosConsorcioForEdit;
      state.error = null;
    },
    ucRateiosConsorcioTimeLineFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateioConsorcioTimeLine = action.payload.ucRateioConsorcioTimeLine;
      state.error = null;
    },
    ucRateiosConsorcioNumerosFetched: (state, action) => {
      state.actionsLoading = false;
      state.ucRateiosConsorcioNumeroForEdit = action.payload.ucRateiosConsorcioNumeroForEdit;
      state.error = null;
    },
    // findUCRateiosConsorcios
    ucRateiosConsorciosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.carregar = false;
      state.ucRateiosConsorciosNovos = null;
      state.ucRateiosConsorciosDuplicadoUC = null;
    },
    ucRateiosConsorciosNovosFetched: (state, action) => {
      console.log(action.payload)
      // const { totalCount, entities } = action.payload;
      state.listLoadingUC = false;
      state.error = null;
      state.ucRateiosConsorciosNovos = action.payload.ucRateiosConsorciosNovos;
      // state.totalCountUC = totalCount;
    },
    // createUCRateio
    ucRateiosConsorcioCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.ucRateiosConsorcio);
    },
    ucRateiosConsorcioRodar: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.erroImportar = action.payload.erroImportar;
      state.carregar = false;
      // state.ucRateioImportacaoID = undefined;
      // state.entities.push(action.payload.ucRateiosConsorcio);
    },
    ucRateiosConsorcioImportado: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.ucRateioImportacaoID = action.payload.ucRateiosConsorcio;
      state.carregar = true;
    },
    ucRateiosConsorcioImportadoProcessar: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.carregar = true;
      // state.ucRateioImportacaoID = action.payload.ucRateiosConsorcio;
    },
    // updateUCRateio
    ucRateiosConsorcioUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.rateioItem.id) {
          return action.payload.rateioItem;
        }
        return entity;
      });
    },
    ucRateiosConsorcioNovaUCUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    ucRateiosConsorcioDuplicadaUCUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteUCRateio
    ucRateiosConsorcioDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUCRateiosConsorcios
    ucRateiosConsorciosConsorciosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    ucRateiosConsorciosUCDuplicadaFetched: (state, action) => {
      console.log(action.payload)
      // const { totalCount, entities } = action.payload;
      state.listLoadingUC = false;
      state.error = null;
      state.ucRateiosConsorciosDuplicadoUC = action.payload.ucRateiosConsorciosDuplicadoUC;
      // state.totalCountUC = totalCount;
    },
  }
});
