import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialTarifasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  tarifaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const tarifasSlice = createSlice({
  name: "tarifas",
  initialState: initialTarifasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTarifaById
    tarifaFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.tarifaForEdit = action.payload.tarifaForEdit;
      state.error = null;
    },
    // findTarifas
    tarifasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.tarifaForEdit = undefined;
    },
    // createTarifa
    tarifaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.tarifa);
    },
    // updateTarifa
    tarifaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.tarifa.idTarifa) {
          return action.payload.tarifa;
        }
        return entity;
      });
    },
    // deleteTarifa
    tarifaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTarifas
    tarifasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // tarifasUpdateState
    tarifasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
