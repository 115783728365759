import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { entities } }) => {

            disableLoading();
            localStorage.setItem('menuPlataforma', JSON.stringify(entities.menuAcesso));
            localStorage.setItem('menuPlataforma', JSON.stringify(entities.menuAcesso));
            localStorage.setItem('@plataforma/user', JSON.stringify({fullname:entities.fullname, idCliente:entities.idCliente, idUsuario:entities.idUsuario,  }));
            localStorage.setItem('permissoes', JSON.stringify(entities.permissoes));
            // , idUsuario:entities.idUsuario
            // email:entities.email,
            if(entities.layout == "L2" && entities.logo == "bomfuturo" ){
              localStorage.setItem('@layout', 'L2');
              localStorage.setItem('@logo', 'bomfuturo');
            } else if(entities.layout == "L1" && entities.logo == "rzk" ) { 
              localStorage.setItem('@layout', 'L1');
              localStorage.setItem('@logo', 'rzk');
            } else if(entities.layout == "L3" && entities.logo == "magpay" ){ 
              localStorage.setItem('@layout', 'L3');
              localStorage.setItem('@logo', 'magpay');
            } else if(entities.layout == "L4" && entities.logo == "bolt" ) { 
              localStorage.setItem('@layout', 'L4');
              localStorage.setItem('@logo', 'bolt');
            }
            props.login(entities.accessToken);
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem('menuPlataforma')
    // localStorage.removeItem('v4-plataforma-auth')
    
  }, [dispatch]);

  var layout = localStorage.getItem('@layout');
  var logo = localStorage.getItem('@logo');
  
  const rzk32 = document.getElementById('rzk32');
  const rzk192 = document.getElementById('rzk192');
  const rzkAppleTouch = document.getElementById('rzkAppleTouch');

  if(layout == null && logo == null) { 
    rzk32.rel = "icon";
    rzk32.href= "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon-100x100.png";
    rzk32.sizes ="32x32";
    rzk192.rel = "icon";
    rzk192.href= "https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
    rzk192.sizes ="192x192"; 
    rzkAppleTouch.rel="apple-touch-icon";
    rzkAppleTouch.href="https://www.rzkenergia.com.br/wp-content/uploads/2018/09/favicon.png";
    document.querySelector("html > head > title").innerHTML = "RZK Energia";
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
            <></>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
           Esqueci minha senha
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Login</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
