import {createSlice} from "@reduxjs/toolkit";

const initialAcessoInvalidosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  acessoInvalidoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const acessoInvalidosSlice = createSlice({
  name: "acessoInvalidos",
  initialState: initialAcessoInvalidosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAcessoInvalidoById
    acessoInvalidoFetched: (state, action) => {
      state.actionsLoading = false;
      state.acessoInvalidoForEdit = action.payload.acessoInvalidoForEdit;
      state.error = null;
    },
    // findAcessoInvalidos
    acessoInvalidosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAcessoInvalido
    acessoInvalidoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.acessoInvalido);
    },
    // updateAcessoInvalido
    acessoInvalidoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.acessoInvalido.id) {
          return action.payload.acessoInvalido;
        }
        return entity;
      });
    },
    // deleteAcessoInvalido
    acessoInvalidoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteAcessoInvalidos
    acessoInvalidosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // acessoInvalidosUpdateState
    acessoInvalidosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
