import {createSlice} from "@reduxjs/toolkit";
const initialAnotacoesLeadState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  anotacaoLeadForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const anotacoesLeadSlice = createSlice({
  name: "anotacoesLead",
  initialState: initialAnotacoesLeadState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAnotacaoLeadById
    anotacaoLeadFetched: (state, action) => {
      state.actionsLoading = false;
      state.anotacaoLeadForEdit = action.payload.anotacaoLeadForEdit;
      state.error = null;
    },
    // findAnotacoesLead
    anotacoesLeadFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAnotacaoLead
    anotacaoLeadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.anotacaoLead);
    },

  }
});
