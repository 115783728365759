import React from "react";
import {useField, useFormikContext} from "formik";
import NumberFormat from 'react-number-format';


export function CEPFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            <NumberFormat
                          format="#####-###"
                          displayType={'text'}
                          value={cellContent}
            />
        </>
    );
}
