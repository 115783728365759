import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialSimulacaoBomFuturosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  simulacaoBomFuturoForEdit: undefined,
  precoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const simulacaoBomFuturosSlice = createSlice({
  name: "simulacaoBomFuturos",
  initialState: initialSimulacaoBomFuturosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSimulacaoBomFuturoById
    simulacaoBomFuturoFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      // if (action.payload.simulacaoBomFuturoForEdit && action.payload.simulacaoBomFuturoForEdit.idSimulacaoBomFuturo) {
      //   action.payload.simulacaoBomFuturoForEdit.endCEPSimulacaoBomFuturo = aplicarMascaraCEP(action.payload.simulacaoBomFuturoForEdit.endCEPSimulacaoBomFuturo);
      // }
      state.simulacaoBomFuturoForEdit = action.payload.simulacaoBomFuturoForEdit;
      // state.precoForEdit = action.payload.novoPreco;
      state.error = null;
    },
    // findSimulacaoBomFuturos
    simulacaoBomFuturosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSimulacaoBomFuturo
    simulacaoBomFuturoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.simulacaoBomFuturo);
    },
    // updateSimulacaoBomFuturo
    simulacaoBomFuturoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.simulacaoBomFuturo.id) {
          return action.payload.simulacaoBomFuturo;
        }
        return entity;
      });
    },
    // deleteSimulacaoBomFuturo
    simulacaoBomFuturoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteSimulacaoBomFuturos
    simulacaoBomFuturosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // simulacaoBomFuturosUpdateState
    simulacaoBomFuturosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
