import * as requestFromServer from "./gdsNotificacaosCrud";
import {gdsNotificacaoSlice, callTypes} from "./gdsNotificacaosSlice";

const {actions} = gdsNotificacaoSlice;

export const fetchGDS = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.gdsFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findGDS(queryParams,tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.gdsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gds";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGD = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.gdFetched({ gdForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGDById(id)
    .then(response => {
        const gd = response.data.entities.resultado;
      dispatch(
        actions.gdFetched({ gdForEdit: gd })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGD = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGD(id)
    .then(response => {
      dispatch(actions.gdDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGD = gdForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGD(gdForCreation)
    .then(response => {
        const gd = response.data.entities;
      dispatch(actions.gdCreated({ gd:gd }));
    })
    .catch(error => {
      error.clientMessage = "Can't create gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGD = (id,gd) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGD(id,gd)
    .then(() => {
      dispatch(actions.gdUpdated({ gd }));
    })
    .catch(error => {
      error.clientMessage = "Can't update gd";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGDS = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGDS(ids)
    .then(() => {
      dispatch(actions.gdsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gds";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaGDS = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaGDS(ids)
      .then(() => {
        dispatch(actions.gdsDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete gds";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};


export const marcarComoLidaGD = (tipoNotificacaoMenu, todos) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaGDS(tipoNotificacaoMenu,  todos)
      // .then(() => {
      //   dispatch(actions.gdsDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gds";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
