/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../../_helpers";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const {user} = useSelector(state => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem('@plataforma/user')) == null?"":JSON.parse(localStorage.getItem('@plataforma/user')).fullname);

  const abrirMenu = e =>{
    // console.log(e, "e.target desse drop down")
    var abrirMenuFechado = document.getElementsByClassName(
    "dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0 dropdown-menu dropdown-menu-right"
    );

    var abrirMenuAberto = document.getElementsByClassName(
      "dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0 dropdown-menu show dropdown-menu-right"
    );

    var classs = document.getElementsByClassName("dropdown");
    // var menu2 = document.getElementById("dropdown-toggle-user-profile")
    var menu = classs.item(1);


    // console.log(menu, "classs");
      
    menu.classList.remove("dropdown");
    menu.classList.add('show','dropdown');



  }

  return (
      <Dropdown drop="down" alignRight onClick={e => abrirMenu(e)}>
        <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-user-profile"
        >
          <div className={"btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"}>
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Oi,</span>
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
              {usuario}
            </span>
            <span className="symbol symbol-35">
              <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"></span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
            className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <>

              <div className="d-flex align-items-center p-8 rounded-top">

                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">

                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{usuario}</div>

              </div>
              <div className="separator separator-solid"></div>


          </>
          <div className="navi navi-spacer-x-0 pt-5">
            <div className="navi-footer  px-8 py-5">
              <Link to="/logout" className="btn btn-light-primary font-weight-bold">
                Encerrar a sessão
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
}
