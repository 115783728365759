import React from "react";
import {useField, useFormikContext} from "formik";
import NumberFormat from 'react-number-format';


export function PrecoFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            <NumberFormat thousandSeparator={true}
                          prefix={'R$'}
                          displayType={'text'}
                          decimalScale={2}
                          decimalSeparator=","
                          fixedDecimalScale
                          thousandSeparator="."
                          value={cellContent}
            />
        </>
    );
}

export function PrecoCompensacaoFormatacao(
    cellContent,
    row,
    rowIndex
) {

    var valorNovo = ""+cellContent
    
    valorNovo = valorNovo.replace('.',',')

    return (
        <>
            <NumberFormat 
                        //thousandSeparator={true}
                          prefix={'R$'}
                          displayType={'text'}
                          decimalScale={2}
                          decimalSeparator=","
                          fixedDecimalScale
                          thousandSeparator="."
                          value={valorNovo}
            />
        </>
    );
}