import * as requestFromServer from "./relatoriosNotificacaosCrud";
import {relatoriosSlice, callTypes} from "./relatoriosNotificacaosSlice";

const {actions} = relatoriosSlice;

export const fetchRelatorios = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.relatoriosFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findRelatorios(queryParams, tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.relatoriosFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find relatorios";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRelatorio = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.relatorioFetched({ relatorioForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRelatorioById(id)
    .then(response => {
        const relatorio = response.data.entities.resultado;
      dispatch(
        actions.relatorioFetched({ relatorioForEdit: relatorio })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find relatorio";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRelatorio = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRelatorio(id)
    .then(response => {
      dispatch(actions.relatorioDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete relatorio";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRelatorio = relatorioForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRelatorio(relatorioForCreation)
    .then(response => {
        const relatorio = response.data.entities;
      dispatch(actions.relatorioCreated({ relatorio:relatorio }));
    })
    .catch(error => {
      error.clientMessage = "Can't create relatorio";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRelatorio = (id,relatorio) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRelatorio(id,relatorio)
    .then(() => {
      dispatch(actions.relatorioUpdated({ relatorio }));
    })
    .catch(error => {
      error.clientMessage = "Can't update relatorio";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRelatorios = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRelatorios(ids)
    .then(() => {
      dispatch(actions.relatoriosDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete relatorios";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaRelatorios = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaRelatorios(ids)
      .then(() => {
        dispatch(actions.relatoriosDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete relatorios";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};



export const marcarComoLidaRelatorio = (tipoNotificacaoMenu) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaRelatorio(tipoNotificacaoMenu)
      // .then(() => {
      //   dispatch(actions.gestaoFaturasDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
