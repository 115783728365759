import React from "react";
import {useField, useFormikContext} from "formik";
import NumberFormat from 'react-number-format';


export function EnergiaFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            <NumberFormat thousandSeparator={true} suffix={' kWh'}
                          displayType={'text'}
                          decimalSeparator="."
                          thousandSeparator=","
                          value={cellContent}
            />
        </>
    );
}



export function EnergiaFormatacaoBoletim(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            <NumberFormat   
                       // thousandSeparator={true} 
                        suffix={' kWh'}
                          displayType={'text'}
                          decimalScale={5}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={cellContent}
            />
        </>
    );
}

export function EnergiaFormatacaoCompensacao(
    cellContent,
    row,
    rowIndex
) {
    // console.log(cellContent, "cellContent")
    var valorNovo = ""+cellContent
    
    valorNovo = valorNovo.replace('.',',')

    return (
        <>
            <NumberFormat 
                        // thousandSeparator={true} 
                          suffix={' kWh'}
                        //   decimalScale={5}
                          displayType={'text'}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={valorNovo}
            />
        </>
    );
}

