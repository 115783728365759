import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { usinasSlice } from "../app/modules/GD/_redux/usinas/usinasSlice";
import { usuariosSlice } from "../app/modules/GD/_redux/usuarios/usuariosSlice";

import { distribuidoresSlice } from "../app/modules/GD/_redux/distribuidores/distribuidoresSlice";

import { clientesSlice } from "../app/modules/GD/_redux/clientes/clientesSlice";

import { medicoesSlice } from "../app/modules/GD/_redux/medicoesSCDE/medicoesSlice";
import { agentesSlice } from "../app/modules/GD/_redux/agentes/agentesSlice";

import { acessosSlice } from "../app/modules/GD/_redux/acessos/acessosSlice";

import { demandasSlice } from "../app/modules/GD/_redux/demandas/demandasSlice";

import { crmSlice } from "../app/modules/GD/_redux/dashboard/crm/crmSlice";

import { historicoUCsSlice } from "../app/modules/GD/_redux/historicoUCs/historicoUCsSlice";

import { historicoRateiosSlice } from "../app/modules/GD/_redux/historicoRateios/historicoRateiosSlice";

import { acessoInvalidosSlice } from "../app/modules/GD/_redux/acessoInvalidos/acessoInvalidosSlice";

import { processoFaturasSlice } from "../app/modules/GD/_redux/processoFaturas/processoFaturasSlice";
import { resultadoUCsSlice } from "../app/modules/GD/_redux/resultadoUCs/resultadoUCsSlice";
import { simulacaoLeadsSlice } from "../app/modules/GD/_redux/simulacaoLeads/simulacaoLeadsSlice";
import { simulacaoTiposSlice } from "../app/modules/GD/_redux/simulacaoTipos/simulacaoTiposSlice";
import { leadsSlice } from "../app/modules/GD/_redux/leads/leadsSlice";

import { leadAnexosSlice } from "../app/modules/GD/_redux/leadAnexos/leadAnexosSlice";
import { leadContatosSlice } from "../app/modules/GD/_redux/leadContatos/leadContatosSlice";

import { leadEmailsSlice } from "../app/modules/GD/_redux/leadEmails/leadEmailsSlice";
import { leadPropostasSlice } from "../app/modules/GD/_redux/leadPropostas/leadPropostasSlice";

import { simulacaoParametrosSlice } from "../app/modules/GD/_redux/simulacaoParametros/simulacaoParametrosSlice";
import { perfilAcessosSlice } from "../app/modules/GD/_redux/perfilAcessos/perfilAcessosSlice";
import { ucParticipantesSlice } from "../app/modules/GD/_redux/ucParticipantes/ucParticipantesSlice";
import { ucRateiosSlice } from "../app/modules/GD/_redux/ucRateios/ucRateiosSlice";
import { custosSlice } from "../app/modules/GD/_redux/custos/custosSlice";

import { gcUcParticipantesSlice } from "../app/modules/GD/_redux/gcUcParticipantes/gcUcParticipantesSlice";
import { gcCustosSlice } from "../app/modules/GD/_redux/gccustos/gcCustosSlice";

import { ucsSlice } from "../app/modules/GD/_redux/ucs/ucsSlice";
import { gdsSlice } from "../app/modules/GD/_redux/gds/gdsSlice";
import { gcsSlice } from "../app/modules/GD/_redux/gcs/gcsSlice";
import { agenteRelatorioSlice } from "../app/modules/GD/_redux/agenteRelatorio/agenteRelatorioSlice";

import { mailBoxsSlice } from "../app/modules/GD/_redux/mailBoxs/mailBoxsSlice";
import { boletimFinanceirosSlice } from "../app/modules/GD/_redux/boletimFinanceiros/boletimFinanceirosSlice";
import { boletimMensalSlice } from "../app/modules/GD/_redux/boletimMensal/boletimMensalSlice";

import { geracaoUsinaSlice } from "../app/modules/GD/_redux/geracaoUsina/geracaoUsinaSlice";

import { importacaoArquivoSlice } from "../app/modules/GD/_redux/importacaoArquivo/importacaoArquivoSlice";

import { templatesSlice } from "../app/modules/GD/_redux/templates/templatesSlice";

import { contratosSlice } from "../app/modules/GD/_redux/contratos/contratosSlice";

import { cronsSlice } from "../app/modules/GD/_redux/crons/cronsSlice";

import { tarifasSlice } from "../app/modules/GD/_redux/tarifas/tarifasSlice";
import { anexosLeadSlice } from "../app/modules/GD/_redux/anexosLead/anexosLeadSlice";

import { usuarioAgentesSlice } from "../app/modules/GD/_redux/usuariosAgente/usuarioAgentesSlice";

import { usuarioClientesSlice } from "../app/modules/GD/_redux/usuariosCliente/usuarioClientesSlice";

import { anotacoesLeadSlice } from "../app/modules/GD/_redux/anotacoesLead/anotacoesLeadSlice";
import { propostasSlice } from "../app/modules/GD/_redux/propostas/propostasSlice";
import { compensacaoManualEnergiaSlice } from "../app/modules/GD/_redux/compensacaoManualEnergia/compensacaoManualEnergiaSlice";


import { calculadorasSlice } from "../app/modules/GD/_redux/calculadora/calculadorasSlice";

import { leadsV2Slice } from "../app/modules/GD/_redux/leadv2/leadsV2Slice";
import { leadAnotacoesSlice } from "../app/modules/GD/_redux/leadAnotacoes/leadAnotacoesSlice";
import { leadAtividadesSlice } from "../app/modules/GD/_redux/leadAtividades/leadAtividadesSlice";

import { operacaoGDSlice } from "../app/modules/GD/_redux/operacaoGD/operacaoGDSlice";

import { nfesSlice } from "../app/modules/GD/_redux/nfes/nfesSlice";

import { contratosUploadSlice } from "../app/modules/GD/_redux/contratosUpload/contratosUploadSlice";
import { telemetriasSlice } from "../app/modules/GD/_redux/telemetria/telemetria/telemetriasSlice";
import { comparativoTelemetriaFaturasSlice } from "../app/modules/GD/_redux/comparativoTelemetriaFatura/ComparativoTelemetriaFaturasSlice";

import { deviceSlice } from "../app/modules/GD/_redux/telemetria/device/deviceSlice";
import { deviceUcSlice } from "../app/modules/GD/_redux/telemetria/deviceUc/deviceUcSlice";
import { deviceMedicaoBrutaSlice } from "../app/modules/GD/_redux/telemetria/deviceMedicaoBruta/deviceMedicaoBrutaSlice";

import { balancosSlice } from "../app/modules/GD/_redux/balancos/balancosSlice";
import { monitoramentoOnlineSlice } from "../app/modules/GD/_redux/telemetria/monitoramentoOnline/monitoramentoOnlineSlice";
import { monitoramentoSensorSlice } from "../app/modules/GD/_redux/telemetria/monitoramentoSensor/monitoramentoSensorSlice";
import { perfilConsumoSensorSlice } from "../app/modules/GD/_redux/telemetria/perfilConsumoSensor/perfilConsumoSensorSlice";
import { telemetriaEnergiaSlice } from "../app/modules/GD/_redux/telemetria/telemetriaEnergia/telemetriaEnergiaSlice";
import { simulacaoBomFuturosSlice } from "../app/modules/GD/_redux/simulacaoBomFuturo/simulacaoBomFuturosSlice";
import { uCCompararRateiosSlice } from "../app/modules/GD/_redux/ucCompararRateios/ucCompararRateiosSlice";

import { tiposProdutoSlice } from "../app/modules/GD/_redux/tiposProduto/tiposProdutoSlice";
import { produtosSlice } from "../app/modules/GD/_redux/produtos/produtosSlice";

import { precoProdutosSlice } from "../app/modules/GD/_redux/precoProdutos/precoProdutosSlice";
import { configuracaoPercentualConsorciosSlice } from "../app/modules/GD/_redux/configuracaoPercentualConsorcios/configuracaoPercentualConsorciosSlice";

import { processoEmailsSlice } from "../app/modules/GD/_redux/processoFaturaEmails/processoEmailsSlice";
import { processoDashboardsSlice } from "../app/modules/GD/_redux/processoFaturaDashboard/processoDashboardsSlice";
import { processoRpasSlice } from "../app/modules/GD/_redux/processoFaturaRpas/processoRpasSlice";
import { processoFtpsSlice } from "../app/modules/GD/_redux/processoFaturaFtps/processoFtpsSlice";

import { superListasSlice } from "../app/modules/GD/_redux/superListas/superListasSlice";
import { usuarioSuperListasSlice } from "../app/modules/GD/_redux/usuariosSuperLista/usuarioSuperListasSlice";

import { configuracaoLeadsSlice } from "../app/modules/GD/_redux/configuracaoLeads/configuracaoLeadsSlice";
import { parceirosSlice } from "../app/modules/GD/_redux/parceiros/parceirosSlice";
import { canaisSlice } from "../app/modules/GD/_redux/canais/canaisSlice";
import { statussSlice } from "../app/modules/GD/_redux/statuss/statussSlice";
import { configuracaoLeadCadastrosSlice } from "../app/modules/GD/_redux/configuracaoLeadCadastros/configuracaoLeadCadastrosSlice";
import { tiposOportunidadeSlice } from "../app/modules/GD/_redux/tiposOportunidade/tiposOportunidadeSlice";
import { atividadesSlice } from "../app/modules/GD/_redux/atividades/atividadesSlice";

import { processoMedicoesSlice } from "../app/modules/GD/_redux/medicao/medicoesSlice";
import { reprocessarsSlice } from "../app/modules/GD/_redux/reprocessars/reprocessarsSlice";
import { reprocessarFaturasIntegracaoSlice } from "../app/modules/GD/_redux/reprocessarFaturasIntegracao/reprocessarFaturasIntegracaoSlice";

import { segmentosSlice } from "../app/modules/GD/_redux/segmentos/segmentosSlice";
import { configurarSegmentosSlice } from "../app/modules/GD/_redux/configurarSegmentos/configurarSegmentosSlice";

import { ucRateiosConsorciosSlice } from "../app/modules/GD/_redux/ucRateiosConsorcios/ucRateiosConsorciosSlice";
import { ucRateiosConsorciosAjustesSlice } from "../app/modules/GD/_redux/ucRateiosConsorciosAjuste/ucRateiosConsorciosAjustesSlice";

import { compensacaoGCUCsSlice } from "../app/modules/GD/_redux/compensacaoGCUCs/compensacaoGCUCsSlice";

import { faturasSlice } from "../app/modules/GD/_redux/faturas/faturasSlice";

import { parametrosCompensacaoSlice } from "../app/modules/GD/_redux/parametrosCompensacao/parametrosCompensacaoSlice";
import { configuracaoParametroGeraisSlice } from "../app/modules/GD/_redux/configuracaoParametroGerais/configuracaoParametroGeraisSlice";
import { reprocessarsNovasFaturasSlice } from "../app/modules/GD/_redux/reprocessarsNovasFaturas/reprocessarsNovasFaturasSlice";

import { reprocessarCompensacaosSlice } from "../app/modules/GD/_redux/reprocessarCompensacaos/reprocessarCompensacaosSlice";

import { uCReprocessadasSlice } from "../app/modules/GD/_redux/ucReprocessadas/ucReprocessadasSlice";

import { downloadFaturasSlice } from "../app/modules/GD/_redux/downloadFaturas/downloadFaturasSlice";

import { informacaoFaturasSlice } from "../app/modules/GD/_redux/informacaoFaturas/informacaoFaturasSlice";

import { historicoCobrancasSlice } from "../app/modules/GD/_redux/historicoCobrancas/historicoCobrancasSlice";
import { configuracaoPagamentosSlice } from "../app/modules/GD/_redux/configuracaoPagamentos/configuracaoPagamentosSlice";
import { arquivoRemesaRetornosSlice } from "../app/modules/GD/_redux/arquivoRemesaRetornos/arquivoRemesaRetornosSlice";
import { cobrancasSlice } from "../app/modules/GD/_redux/cobrancas/cobrancasSlice";


import { administrativosSlice } from "../app/modules/GD/_redux/administrativos/administrativosSlice";
import { crmsSlice } from "../app/modules/GD/_redux/crms/crmsSlice";
import { gestaoFaturasSlice } from "../app/modules/GD/_redux/gestaoFaturas/gestaoFaturasSlice";
import { mercadoLivresSlice } from "../app/modules/GD/_redux/mercadoLivres/mercadoLivresSlice";

import { gdsNotificacaoSlice } from "../app/modules/GD/_redux/gdsNotificacao/gdsNotificacaosSlice";
import { relatoriosSlice } from "../app/modules/GD/_redux/relatoriosNotificacao/relatoriosNotificacaosSlice";
import { configuracaosSlice } from "../app/modules/GD/_redux/configuracaos/configuracaosSlice";

import { segmentoClientesSlice} from "../app/modules/GD/_redux/segmentoClientes/segmentoClientesSlice";


import { regraCobrancasSlice } from "../app/modules/GD/_redux/regraCobrancas/regraCobrancasSlice";

import { contabilidadesSlice } from "../app/modules/GD/_redux/contabilidades/contabilidadesSlice";
import { situacaoUCSSlice }from "../app/modules/GD/_redux/situacaoUCS/situacaoUCSSlice";
import { leadCicloVendasSlice } from "../app/modules/GD/_redux/leadCiclo/leadCicloVendasSlice";

import {ucRateiosGDSlice } from  "../app/modules/GD/_redux/ucRateiosGD/ucRateiosGDSlice";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  clientes: clientesSlice.reducer,
  calculadoras: calculadorasSlice.reducer,
  tarifas: tarifasSlice.reducer,
  propostas: propostasSlice.reducer,
  agentes: agentesSlice.reducer,
  usinas: usinasSlice.reducer,
  crm: crmSlice.reducer,
  distribuidores: distribuidoresSlice.reducer,
  simulacaoLeads: simulacaoLeadsSlice.reducer,
  simulacaoTipos: simulacaoTiposSlice.reducer,
  simulacaoParametros: simulacaoParametrosSlice.reducer,
  perfilAcessos: perfilAcessosSlice.reducer,
  usuarios: usuariosSlice.reducer,
  leadAnexos: leadAnexosSlice.reducer,
  leadEmails: leadEmailsSlice.reducer,
  leadPropostas: leadPropostasSlice.reducer,
  leadContatos: leadContatosSlice.reducer,

  importacaoArquivo: importacaoArquivoSlice.reducer,

  crons: cronsSlice.reducer,
  templates: templatesSlice.reducer,

  contratos: contratosSlice.reducer,

  acessos: acessosSlice.reducer,
  nfes: nfesSlice.reducer,
  medicoes: medicoesSlice.reducer,
  mailBoxs: mailBoxsSlice.reducer,
  demandas: demandasSlice.reducer,
  historicoUCs: historicoUCsSlice.reducer,
  historicoRateios: historicoRateiosSlice.reducer,
  acessoInvalidos: acessoInvalidosSlice.reducer,
  processoFaturas: processoFaturasSlice.reducer,
  anexosLead: anexosLeadSlice.reducer,
  anotacoesLead: anotacoesLeadSlice.reducer,

  boletimFinanceiros: boletimFinanceirosSlice.reducer,
  boletimMensal: boletimMensalSlice.reducer,
  geracaoUsina: geracaoUsinaSlice.reducer,
  agenteRelatorio: agenteRelatorioSlice.reducer,
  resultadoUCs: resultadoUCsSlice.reducer,

  ucParticipantes: ucParticipantesSlice.reducer,
  ucRateios: ucRateiosSlice.reducer,
  uCCompararRateios: uCCompararRateiosSlice.reducer,

  custos: custosSlice.reducer,

  gds: gdsSlice.reducer,
  gcs: gcsSlice.reducer,
  ucs: ucsSlice.reducer,
  leads: leadsSlice.reducer,

  usuarioAgentes: usuarioAgentesSlice.reducer,
  usuarioClientes: usuarioClientesSlice.reducer,
  leadsV2: leadsV2Slice.reducer,
  leadAnotacoes: leadAnotacoesSlice.reducer,
  leadAtividades: leadAtividadesSlice.reducer,

  operacaoGD: operacaoGDSlice.reducer,
  compensacaoManualEnergia: compensacaoManualEnergiaSlice.reducer,
  gcUcParticipantes: gcUcParticipantesSlice.reducer,
  gcCustos: gcCustosSlice.reducer,

  medicoes: medicoesSlice.reducer,
  nfes: nfesSlice.reducer,
  contratosUpload: contratosUploadSlice.reducer,
  telemetrias: telemetriasSlice.reducer,
  comparativoTelemetriaFaturas: comparativoTelemetriaFaturasSlice.reducer,

  device: deviceSlice.reducer,
  deviceUc: deviceUcSlice.reducer,
  deviceMedicaoBruta: deviceMedicaoBrutaSlice.reducer,
  balancos: balancosSlice.reducer,
  monitoramentoOnlines: monitoramentoOnlineSlice.reducer,
  monitoramentoSensores: monitoramentoSensorSlice.reducer,
  perfilConsumoSensores: perfilConsumoSensorSlice.reducer,
  telemetriaEnergia: telemetriaEnergiaSlice.reducer,
  simulacaoBomFuturos: simulacaoBomFuturosSlice.reducer,

  tiposProduto: tiposProdutoSlice.reducer,
  produtos: produtosSlice.reducer,

  processoEmails: processoEmailsSlice.reducer,
  processoDashboards: processoDashboardsSlice.reducer,
  processoRpas: processoRpasSlice.reducer,
  processoFtps: processoFtpsSlice.reducer,

  superListas: superListasSlice.reducer,

  usuarioSuperListas: usuarioSuperListasSlice.reducer,
  configuracaoLeads: configuracaoLeadsSlice.reducer,
  parceiros: parceirosSlice.reducer,
  canais: canaisSlice.reducer,
  statuss: statussSlice.reducer,
  configuracaoLeadCadastros: configuracaoLeadCadastrosSlice.reducer,
  tiposOportunidade: tiposOportunidadeSlice.reducer,
  atividades: atividadesSlice.reducer,

  medicoes: processoMedicoesSlice.reducer,
  reprocessars: reprocessarsSlice.reducer,
  reprocessarFaturasIntegracao: reprocessarFaturasIntegracaoSlice.reducer,
  segmentos: segmentosSlice.reducer,
  configurarSegmentos: configurarSegmentosSlice.reducer,

  ucRateiosConsorcios: ucRateiosConsorciosSlice.reducer,

  ucRateiosConsorciosAjustes: ucRateiosConsorciosAjustesSlice.reducer,

  compensacaoGCUCs: compensacaoGCUCsSlice.reducer,

  faturas: faturasSlice.reducer,

  parametrosCompensacao: parametrosCompensacaoSlice.reducer,

  configuracaoParametroGerais: configuracaoParametroGeraisSlice.reducer,
  reprocessarsNovasFaturas: reprocessarsNovasFaturasSlice.reducer,
  precoProdutos: precoProdutosSlice.reducer,
  configuracaoPercentualConsorcios: configuracaoPercentualConsorciosSlice.reducer,

  reprocessarCompensacaos: reprocessarCompensacaosSlice.reducer,

  uCReprocessadas: uCReprocessadasSlice.reducer,

  downloadFaturas: downloadFaturasSlice.reducer,
  informacaoFaturas: informacaoFaturasSlice.reducer,

  historicoCobrancas: historicoCobrancasSlice.reducer,
  configuracaoPagamentos: configuracaoPagamentosSlice.reducer,
  arquivoRemesaRetornos: arquivoRemesaRetornosSlice.reducer,
  cobrancas: cobrancasSlice.reducer,

  administrativos: administrativosSlice.reducer,
  crms: crmsSlice.reducer,
  gestaoFaturas: gestaoFaturasSlice.reducer,
  mercadoLivres: mercadoLivresSlice.reducer,

  gdsNotificacao: gdsNotificacaoSlice.reducer,
  relatoriosNotificacao: relatoriosSlice.reducer,
  configuracaos: configuracaosSlice.reducer ,
  segmentoClientes: segmentoClientesSlice.reducer,

  regraCobrancas: regraCobrancasSlice.reducer ,
  contabilidades: contabilidadesSlice.reducer,
  situacaoUCS: situacaoUCSSlice.reducer,

  leadCicloVendas: leadCicloVendasSlice.reducer,


  ucRateiosGD: ucRateiosGDSlice.reducer 

});

export function* rootSaga() {
  yield all([auth.saga()]);
}
