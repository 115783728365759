import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialMedicoesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  medicaoForEdit: undefined,
  lastError: null,
  cicloqtde1:0,
  cicloqtde2:0,
  cicloqtde3:0,
  cicloqtde4:0,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const medicoesSlice = createSlice({
  name: "medicoes",
  initialState: initialMedicoesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMedicaoById
    medicaoFetched: (state, action) => {


      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.medicaoForEdit = action.payload.medicaoForEdit;
      state.error = null;

    },
    // findMedicoes
    medicoesFetched: (state, action) => {
      const { totalCount, entities ,cicloqtde1,cicloqtde2,cicloqtde3,cicloqtde4} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.cicloqtde1=cicloqtde1;
      state.cicloqtde2=cicloqtde2;
      state.cicloqtde3=cicloqtde3;
      state.cicloqtde4=cicloqtde4;
    },
    // createMedicao
    medicaoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.medicao);
    },
    // updateMedicao
    medicaoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.medicao.id) {
          return action.payload.medicao;
        }
        return entity;
      });
    },
    // deleteMedicao
    medicaoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteMedicoes
    medicoesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // medicoesUpdateState
    medicoesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
