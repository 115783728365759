import React from "react";
import { useField, useFormikContext } from "formik";
import moment from 'moment';
export function DataFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            {cellContent && moment(cellContent).format('DD-MM-YYYY')}
        </>
    );
}

export function DataFormatacaoBilling(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            {cellContent && moment(cellContent).format('DD/MM/YYYY')}
        </>
    );
}
