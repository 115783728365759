import React, { useEffect } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';


/*const options = [
    { value: 'one', label: 'Option One' },
    { value: 'two', label: 'Option Two' },
];*/

export class ListaDupla extends React.Component {
    state = {
        selected: this.props.selected,
    };

    onChange = (selected) => {
        this.setState({ selected });
        if (this.props.onChange) {
            this.props.onChange(selected);

        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected && this.props.selected != null) {

            const selectedTemp = this.props.selected.toString().split(",");
            const selected = [];
            for (var i = 0; i < selectedTemp.length; i++) {
                selected.push(parseInt(selectedTemp[i]));
            }
            this.setState({ selected });
        }
    }
    render() {
        const { selected } = this.state;

        return (
            <>
                {this.props.label && <label>{this.props.label}</label>}
                < DualListBox
                    canFilter
                    options={this.props.options}
                    selected={selected}
                    onChange={this.onChange}
                />
            </>
        );
    }
}
