import React from "react";
import { Route } from "react-router-dom";
import { GdsLoadingDialog } from "./gds-loading-dialog/GdsLoadingDialog";
import { GdDeleteDialog } from "./gd-delete-dialog/GdDeleteDialog";
import { GdsDeleteDialog } from "./gds-delete-dialog/GdsDeleteDialog";
import { GdsFetchDialog } from "./gds-fetch-dialog/GdsFetchDialog";
import { GdsUpdateStatusDialog } from "./gds-update-status-dialog/GdsUpdateStatusDialog";
import { GdsCard } from "./GdsCard";
import { GdsUIProvider } from "./GdsUIContext";

export function GdsPage({ history }) {
  const gdsUIEvents = {
    newGdButtonClick: () => {
      history.push("/gd/gds/new");
    },
    openEditGdPage: (id) => {
      history.push(`/gd/gds/${id}/edit`);
    },
    openDeleteGdDialog: (id) => {
      history.push(`/gd/gds/${id}/delete`);
    },
    openDeleteGdsDialog: () => {
      history.push(`/gd/gds/deleteGds`);
    },
    openFetchGdsDialog: () => {
      history.push(`/gd/gds/fetch`);
    },
    openUpdateGdsStatusDialog: () => {
      history.push("/gd/gds/updateStatus");
    },
  };

  return (
    <GdsUIProvider gdsUIEvents={gdsUIEvents}>
      <GdsLoadingDialog />
      <Route path="/gd/gds/deleteGds">
        {({ history, match }) => (
          <GdsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/gd/gds");
            }}
          />
        )}
      </Route>
      <Route path="/gd/gds/:id/delete">
        {({ history, match }) => (
          <GdDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/gd/gds");
            }}
          />
        )}
      </Route>
      <Route path="/gd/gds/fetch">
        {({ history, match }) => (
          <GdsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/gd/gds");
            }}
          />
        )}
      </Route>
      <Route path="/gd/gds/updateStatus">
        {({ history, match }) => (
          <GdsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/gd/gds");
            }}
          />
        )}
      </Route>
      <GdsCard />
    </GdsUIProvider>
  );
}
