import * as requestFromServer from "./crmsCrud";
import {crmsSlice, callTypes} from "./crmsSlice";

const {actions} = crmsSlice;

export const fetchCRMS = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.crmsFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findCRMS(queryParams, tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.crmsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find crms";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCRM = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.crmFetched({ crmForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCRMById(id)
    .then(response => {
        const crm = response.data.entities.resultado;
      dispatch(
        actions.crmFetched({ crmForEdit: crm })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find crm";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCRM = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCRM(id)
    .then(response => {
      dispatch(actions.crmDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete crm";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCRM = crmForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCRM(crmForCreation)
    .then(response => {
        const crm = response.data.entities;
      dispatch(actions.crmCreated({ crm:crm }));
    })
    .catch(error => {
      error.clientMessage = "Can't create crm";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCRM = (id,crm) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCRM(id,crm)
    .then(() => {
      dispatch(actions.crmUpdated({ crm }));
    })
    .catch(error => {
      error.clientMessage = "Can't update crm";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCRMS = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCRMS(ids)
    .then(() => {
      dispatch(actions.crmsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete crms";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaCRMS = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaCRMS(ids)
      .then(() => {
        dispatch(actions.crmsDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete crms";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};


export const marcarComoLidaCRM = (tipoNotificacaoMenu) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaCRM(tipoNotificacaoMenu)
      // .then(() => {
      //   dispatch(actions.gestaoFaturasDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
