import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialSimulacaoParametrosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,

  entities: null,
  simulacaoParametroForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const simulacaoParametrosSlice = createSlice({
  name: "simulacaoParametros",
  initialState: initialSimulacaoParametrosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSimulacaoParametroById
    simulacaoParametroFetched: (state, action) => {


      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.simulacaoParametroForEdit = action.payload.simulacaoParametroForEdit;
      state.error = null;

    },
    // findSimulacaoParametros
    simulacaoParametrosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;

    },
    // createSimulacaoParametro
    simulacaoParametroCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.simulacaoParametro);
    },
    // updateSimulacaoParametro
    simulacaoParametroUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
        // if (entity.id === action.payload.simulacaoParametro.id) {
        //   return action.payload.simulacaoParametro;
        // }
        // return entity;
      // });
    },
    // deleteSimulacaoParametro
    simulacaoParametroDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteSimulacaoParametros
    simulacaoParametrosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // simulacaoParametrosUpdateState
    simulacaoParametrosStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
