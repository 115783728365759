import { createSlice } from "@reduxjs/toolkit";

const initialMedicoesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  medicaoForEdit: undefined,
  lastError: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const processoMedicoesSlice = createSlice({
  name: 'medicoes',
  initialState: initialMedicoesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    medicaoFetched: (state, action) => {
      state.actionsLoading = false;
      state.medicaoForEdit = action.payload.medicaoForEdit;
      state.error = null;
    },
    medicoesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    medicaoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // console.log(action);
      // state.entities.push(action.payload.medicao);
    },
    medicaoFilaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // console.log(action);
      // state.entities.push(action.payload.medicao);
    },
    medicaoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.idMedicao === action.payload.medicao.idMedicao) {
          return action.payload.medicao;
        }
        return entity;
      });
    },
    medicaoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id === action.payload.id);
    },
    medicoesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
