import React from "react";
import {useField, useFormikContext} from "formik";
import moment from 'moment';
export function DataHoraFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            {cellContent && moment(cellContent).format('DD-MM-YYYY HH:mm')}
        </>
    );
}

export function DataHoraFormatacaoBilling(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            {cellContent && moment(cellContent).format('DD/MM/YYYY - HH:mm')}
        </>
    );
}
