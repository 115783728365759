// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/gds/gdsActions";
import * as uiHelpers from "../GdsUIHelpers";
import { GdExcelExport } from "../gd-excel-export/GdExcelExport";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  toAbsoluteUrl
} from "../../../../../../_layout/_helpers";
import * as columnFormatters from "./column-formatters";
import { EnergiaHtml, Pagination } from "../../../../../../_layout/_partials/controls";
import { useGdsUIContext } from "../GdsUIContext";
import moment from 'moment';

import SVG from "react-inlinesvg";
import { getSelectDataClienteGD, getSelectMesesGD } from "../../../../../lib/selectAPI";

export function GdsTable() {
  // Gds UI Context
  const gdsUIContext = useGdsUIContext();
  const gdsUIProps = useMemo(() => {
    return {
      ids: gdsUIContext.ids,
      setIds: gdsUIContext.setIds,
      queryParams: gdsUIContext.queryParams,
      setQueryParams: gdsUIContext.setQueryParams,
      openEditUsinaPage: gdsUIContext.openEditUsinaPage,
      openDeleteUsinaDialog: gdsUIContext.openDeleteUsinaDialog,
    };
  }, [gdsUIContext]);

  // Getting curret state of gds list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.gds }),
    shallowEqual
  );
  const { totalCount, entities, listLoading, gdForEditFiltro } = currentState;
  // Gds Redux state
  const history = useHistory();
  const [mesGdCombo, setMesGdCombo] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    gdsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchGds(gdsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gdsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "idUsina",
      text: "Usina (ID)",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "nomeDistribuidora",
      text: "Distribuidora",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "nomeUsina",
      text: "Nome Usina",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "endCEPUsina",
      text: "CEP",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "endCidadeUsina",
      text: "Cidade",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "endUFUsina",
      text: "UF",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "situacao",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.SituacaoColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditUsinaPage: gdsUIProps.openEditUsinaPage,
        openDeleteUsinaDialog: gdsUIProps.openDeleteUsinaDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: gdsUIProps.queryParams.pageSize,
    page: gdsUIProps.queryParams.pageNumber,
  };

  const editarGestao = (id) => {
    let path = `/gd/gds/${id}/edit`;
    history.push(path);
  };
  const [forceRefresh, setForceRefresh] = useState(0)
  const [listAtribibutos, setlistAtribibutos] = useState([]);
  const [listDataCliente, setListDataCliente] = useState([]);
  const onChange = (e, id) => {
    if (e != "-") {
      let _listaSelecionado = listAtribibutos;
      _listaSelecionado[id] = null
      setlistAtribibutos(_listaSelecionado)
      dispatch(actions.ObterGDData(id, e));

      let _listaDataSelecionado = listDataCliente;
      _listaDataSelecionado[id] = e
      setListDataCliente(_listaDataSelecionado)

    }
  };

  const avancar = (e, id, idCliente) => {
    localStorage.setItem('GdID', idCliente);
    localStorage.setItem('GdData', listDataCliente[id] == undefined ? moment().format('MMM/YYYY').toString() : listDataCliente[id]);
    history.push(e);
  };

  // localStorage.setItem('menuPlataforma', JSON.stringify(entities.menuAcesso));

  useEffect(() => {
    if (gdForEditFiltro != undefined) {
      let _listaSelecionado = listAtribibutos;
      _listaSelecionado[gdForEditFiltro.idExtratoGdUsina] = gdForEditFiltro
      console.log(gdForEditFiltro)
      setlistAtribibutos(_listaSelecionado)
      setForceRefresh(Math.random() * 99999999999);
    } else {
      setlistAtribibutos([])
    }

  }, [gdForEditFiltro, dispatch]);
  useEffect(() => {
    getSelectMesesGD(false, true).then(function (retorno) {
      setMesGdCombo(retorno);
    });

   

  }, [])


  useEffect(() => {

  }, [listLoading, dispatch]);

  useEffect(() => {
    if (entities != undefined) {
      dispatch(actions.ObterGDData(null));
    }
  }, [entities, dispatch]);

  return (
    <>

      {entities !== null && entities.length > 0 && (

        <div className="row">
          {entities.map((item, i) =>
            <div className="col-xl-4">
              {/*begin::Card*/}
              <div className="card card-custom gutter-b card-stretch" style={{ border: "1px solid #eee" }}>
                {/*begin::Body*/}
                <div className="card-body"  >
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Pic*/}

                    <div className="symbol-group symbol-hover">
                      <div className="symbol symbol-30 symbol-lg-40 symbol-circle">
                        <img src={item.logoMarcaCliente} alt="image" width={"50%"} />
                        {/* <img src={"/media/project-logos/"+item.contratoGestao+".jpg"} alt="image" width={"50%"}/> */}
                      </div>

                      {/*  <div className="symbol symbol-30 symbol-lg-40 symbol-circle">
                                    <img src="/media/project-logos/11.jpg" alt="image" />
                                  </div>*/}
                      {/*  <div className="symbol  symbol-10 symbol-lg-20 symbol-circle">
                                    <span className="symbol-label font-weight-bold">10+</span>
                                  </div>*/}
                    </div>
                    {/*end::Pic*/}
                    {/*begin::Info*/}
                    <div className="d-flex flex-column mr-auto">
                      {/*begin: Title*/}
                      <div className="d-flex flex-column mr-auto">
                        <a onClick={() => editarGestao(item.id)} className="text-dark text-hover-primary font-size-h4 font-weight-bolder mb-1">{item.contratoGestao}</a>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Info*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar mb-7">
                      <div className="dropdown dropdown-inline" data-toggle="tooltip" title="Acesso Rapido" data-placement="left">

                        <a onClick={() => editarGestao(item.id)} className="btn btn-icon btn-light btn-hover-primary">
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                          </span>
                        </a>&nbsp;
                        <GdExcelExport />
                      </div>
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Description*/}
                  {/*begin::Data*/}
                  <div className="d-flex mb-3 mt-0">
                    <div className="d-flex align-items-center mr-1">

                      <span className="font-weight-bold mr-3">Mês apuração</span>
                      <div className="font-weight-bold mr-1">
                        {/* {listDataCliente && listDataCliente[item.id] && */}
                        <select className="form-control mb-3" id="exampleSelect1"
                          onChange={(e) => {
                            // setFieldValue("tipoLead", e.target.value);
                            onChange(e.target.value, item.id);
                          }}>
                          {mesGdCombo.map((item) => (
                                          <option value={item.value}>
                                            {item.mesRef}
                                          </option>
                                        ))}
                        </select>
                        {/* } */}
                      </div>

                    </div>
                  </div>
                  {/*end::Data*/}
                  {/*begin::Info*/}
                  {/* {forceRefresh && listAtribibutos ? */}
                  <div className="mb-3 mt-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-dark-75 font-weight-bolder mr-2">Energia Consumida</span>
                      <a href="#" className="text-muted text-hover-primary">
                        {forceRefresh && listAtribibutos ?
                          <EnergiaHtml value={listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].energiaConsumida} /> : null}</a>
                    </div>
                    <div className="d-flex justify-content-between align-items-cente my-1">
                      <span className="text-dark-75 font-weight-bolder mr-2">Energia Gerada</span>
                      <a href="#" className="text-muted text-hover-primary">
                        {forceRefresh && listAtribibutos ?
                          <EnergiaHtml value={listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].energiaGerada} /> : null}</a>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-dark-75 font-weight-bolder mr-2">Energia Injetada</span>
                      <span className="text-muted font-weight-bold">
                        {forceRefresh && listAtribibutos ?
                          <EnergiaHtml value={listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].energiaInjetada} /> : null}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-cente my-1">
                      <span className="text-dark-75 font-weight-bolder mr-2">Energia Compensada</span>
                      <a href="#" className="text-muted text-hover-primary">
                        {forceRefresh && listAtribibutos ?
                          <EnergiaHtml value={listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].energiaCompensada} /> : null}</a>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-dark-75 font-weight-bolder mr-2">Crédito Acumulado</span>
                      <span className="text-muted font-weight-bold">
                        {forceRefresh && listAtribibutos ?
                          <EnergiaHtml value={listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].creditoAcumulado} /> : null}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-dark-75 font-weight-bolder mr-2">Faturas Replicadas</span>
                      <span className="text-muted font-weight-bold">
                        {listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].qtdeFaturasReplicadas}</span>
                    </div>
                  </div>
                  {/* :null
                              } */}

                  {/*end::Info*/}
                  {/*end::Description*/}
                  {/*begin::Progress*/}
                  <div className="d-flex mb-0 mt-0 align-items-center">
                    <span className="d-block font-weight-bold mr-2">Importação das Faturas (
                      {listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].qtdeUnidadesConsumidoras}
                      /
                      {listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].qtdeFaturaProcessadas})</span>
                    <div className="d-flex flex-row-fluid align-items-center">
                      <div className="progress progress-xs mt-1 mb-2 w-100">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                      <span className="ml-3 font-weight-bolder">{listAtribibutos && listAtribibutos[item.id] && listAtribibutos[item.id].consumidorasProcessadas}%</span>
                    </div>
                  </div>


                  {/*ebd::Progress*/}
                </div>
                {/*end::Body*/}
                {/*begin::Footer*/}
                <div className="card-footer d-flex align-items-center">
                  <div className="d-flex">
                    <div className="d-flex align-items-center mr-2">
                      <span className="svg-icon svg-icon-gray-500">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Text/Bullet-list.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <path d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z" fill="#000000" />
                            <path d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z" fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <a className="font-weight-bolder text-primary ml-2"
                        onClick={() => avancar("/gd/relatorios/boletimmensal", item.id, item.idCliente)}
                      >B.Mensal</a>
                    </div>
                    <div className="d-flex align-items-center mr-2">
                      <span className="svg-icon svg-icon-gray-500">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000" />
                            <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <a className="font-weight-bolder text-primary ml-2"
                        onClick={() => avancar("/gd/consulta/resultado-ucs", item.id, item.idCliente)}
                      >UCs</a>
                    </div>
                    <div className="d-flex align-items-center mr-2">
                      <span className="svg-icon svg-icon-gray-500">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group-chat.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000" />
                            <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <a href="#" className="font-weight-bolder text-primary ml-2">Erro(s)</a>
                    </div>
                  </div>
                </div>
                {/*end::Footer*/}
              </div>
              {/*end:: Card*/}
            </div>
          )}
        </div>


      )}

    </>

  );
}
