import React from "react";
import {
  GdStatusCssClasses,
    GdSituacao
} from "../../GdsUIHelpers";

export const SituacaoColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      GdStatusCssClasses[row.situacao=="A"?0:1]
    } label-inline`}
  >
    {GdSituacao[row.situacao=="A"?0:1]}
  </span>
);
