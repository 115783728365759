const prodRZK = {
    url: {
        apiEndPoint: 'https://app.rzkenergia.com.br/api',
        urlLogout: 'https://app.rzkenergia.com.br/logout'
    }
};
const prodBomFuturo = {
    url: {
        apiEndPoint: 'https://matogrossoenergia.rzkenergia.net/api',
        urlLogout: 'https://matogrossoenergia.rzkenergia.net/logout'
    }
};
const dev = {
    url: {
        apiEndPoint: 'http://localhost:8181/api',
        urlLogout: 'http://localhost:3000/logout',
    }
};
const hml = {
    url: {
        apiEndPoint: 'https://d2ol768y771cii.cloudfront.net/api',
        urlLogout: 'https://d2ol768y771cii.cloudfront.net/logout',
    }
};

const areaClienteProd = {
    url: {
        apiEndPoint: 'https://d16zcybb7vr37n.cloudfront.net/api',
        urlLogout: 'https://d16zcybb7vr37n.cloudfront.net/logout',
    }
};


const magpayPROD = {
    url: {
        apiEndPoint: 'https://app.magpayenergia.com.br/api',
        urlLogout: 'https://app.magpayenergia.com.br/logout',
    }
};
const boltPROD = {
    url: {
        apiEndPoint: '',
        urlLogout: '',
    }
};
const devDocker = {
    url: {
        apiEndPoint: 'http://172.17.0.2:8181/api',
        urlLogout: 'http://localhost:3000/logout',
    }
};

const prodBomFuturoHomologacao = {
    url: {
        apiEndPoint: 'https://d2ttou6gwbi2eo.cloudfront.net/api',
        urlLogout: 'https://d2ttou6gwbi2eo.cloudfront.net/logout'
    }
};

export const config = prodBomFuturo;