import StringMask from "string-mask";
 function formatarTelefone(valor)  {
    return new Promise(function (resolve, reject) {
        var formatter = new StringMask('(00) 00000-0000');
        const valorSemMask = valor.replace(/[\(\)\.\s-]+/g, '');
        const valorMask = formatter.apply(valorSemMask);
        resolve({valorMask:valorMask,valor:valorSemMask})
    });
};
 function formatarCEP(valor)  {
    return new Promise(function (resolve, reject) {
        var formatter = new StringMask('00000-000');
        const valorSemMask = valor.replace(/-/g, '');
        const valorMask = formatter.apply(valorSemMask);
        resolve({valorMask:valorMask,valor:valorSemMask})
    });
};
export function aplicarMascaraTelefone(valor) {
    if(valor!=null) {
        var formatter = new StringMask('(00) 00000-0000');
        const valorSemMask = valor.replace(/[\(\)\.\s-]+/g, '');
        return formatter.apply(valorSemMask);
    }
    else
    {
        return "";
    }
}
export function aplicarMascaraCEP(valor) {
    if(valor!=null) {
        var formatter = new StringMask('00000-000');
        const valorSemMask = valor.replace(/[\(\)\.\s-]+/g, '');
        return formatter.apply(valorSemMask);
    }
    else
    {
        return "";
    }
}
export function retirarMascaraCEP(valor) {
        return valor.replace(/-/g, '');
}
export function retirarMascaraTelefone(valor) {
    return valor.replace(/[\(\)\.\s-]+/g, '');
}
export function retirarMascaraCNPJCPF(valor) {
    return valor.replace(/[\-\/\.\s-]+/g, '');
}
export function formatarCampo(e,setFieldValue,field,formato)  {
    switch (formato) {
        case "telefone":
            formatarTelefone(e.target.value).then(function(retorno)
            {
                setFieldValue(field, retorno.valorMask);
            });
            break;
        case "cep":
            formatarCEP(e.target.value).then(function(retorno)
            {
                setFieldValue(field, retorno.valorMask);
            });
            break;
    }
};
