import { createSlice } from "@reduxjs/toolkit";

const initialOperacoesGDState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: { custo: { consumo: 0, diasMedidos: 0, energiaCompensada: null, totalGD: null, totalSGD: null, economia: null, economia: null, energiaGerada: 0, numeroUCs: 0, locacaoTerra: 0, locacaoEquipamento: 0, om: 0, faturamentoUCs: 0, demandaGeracao: 0 }, consumo: [] },
  detalhes: null,
  GraficoGeracaoMensal: [],
  GraficoEnergiaCompensada: null,
  operacaoGDForEdit: undefined,
  lastError: null,
  dataAtualizacao:undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const operacaoGDSlice = createSlice({
  name: "operacaoGD",
  initialState: initialOperacoesGDState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findOperacoesGD
    DetalhesoperacaoGDFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.detalhes = entities;
    },
    GeracaoMensalFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.GraficoGeracaoMensal = entities;
    },
    EnergiaCompensadaFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.GraficoEnergiaCompensada = entities;
    },
    DataSnapFetched: (state, action) => {
      const { entities } = action.payload;
      state.dataAtualizacao = entities==null?undefined:entities;
    }
  }
});
