import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./GdsUIHelpers";

const GdsUIContext = createContext();

export function useGdsUIContext() {
  return useContext(GdsUIContext);
}

export const GdsUIConsumer = GdsUIContext.Consumer;

export function GdsUIProvider({ gdsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newGdButtonClick: gdsUIEvents.newGdButtonClick,
    openEditGdPage: gdsUIEvents.openEditGdPage,
    openDeleteGdDialog: gdsUIEvents.openDeleteGdDialog,
    openDeleteGdsDialog: gdsUIEvents.openDeleteGdsDialog,
    openFetchGdsDialog: gdsUIEvents.openFetchGdsDialog,
    openUpdateGdsStatusDialog:
      gdsUIEvents.openUpdateGdsStatusDialog,
  };

  return (
    <GdsUIContext.Provider value={value}>
      {children}
    </GdsUIContext.Provider>
  );
}
