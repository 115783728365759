import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new gestaoFaturas to the server

export function createGestaoFatura(gestaoFatura) {
  gestaoFatura.idDistribuidora = parseInt(gestaoFatura.idDistribuidora);
  gestaoFatura.idCliente = parseInt(gestaoFatura.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:gestaoFatura,
  };

  return axios(request);

}
// READ
// Server should return filtered gestaoFaturas by productId
export function getAllProductGestaoFaturasByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getGestaoFaturaById(gestaoFaturaId) {
  return axios.get(`${SPECIFICATIONS_URL}/${gestaoFaturaId}`);
}

// Server should return sorted/filtered gestaoFaturas and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/gestaoFaturasfind/{productId}'. Should be 'api/gestaoFaturas/find/{productId}'!!!
export function findGestaoFaturas(queryParams, todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "1";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the gestaoFatura
export function updateGestaoFatura(id,gestaoFatura) {
  gestaoFatura.IdGestaoFatura =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: gestaoFatura,
  };
  return axios(request);
}

// DELETE => delete the gestaoFatura
export function deleteGestaoFatura(gestaoFaturaId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${gestaoFaturaId}`);
}

export function deleteGestaoFaturas(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaGestaoFaturas(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}

export function marcarComoLidaGestaoFaturas(tipoNotificacaoMenu, todas) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas:  todas },
  };

  return axios(request);
}
