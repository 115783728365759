import React from "react";
import NumberFormat from "react-number-format";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel &&  errors[field.name] && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function InputMesesDados({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {

  return (
      <>
          {label && <label>{label}</label>}
          <NumberFormat 
            suffix={' meses'}
              value={field.value}
              onValueChange={val => {
                  form.setFieldValue(field.name, val.floatValue);
              }}
              className={getFieldCSSClasses(form.touched[field.name], form.errors[field.name])}
              {...props}
          />
          {withFeedbackLabel && form.errors[field.name] && (
              <FieldFeedbackLabel
                  error={form.errors[field.name]}
                  touched={form.touched[field.name]}
                  label={label}
                  type={type}
                  customFeedbackLabel={customFeedbackLabel}
              />
          )}
      </>
  );
}
