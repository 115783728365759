import {createSlice} from "@reduxjs/toolkit";
const initialFaturasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  faturaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const faturasSlice = createSlice({
  name: "faturas",
  initialState: initialFaturasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFaturaById
    faturaFetched: (state, action) => {
      state.actionsLoading = false;
      state.faturaForEdit = action.payload.faturaForEdit;
      state.error = null;
    },
    // findFaturas
    faturasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createFatura
    faturaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.fatura);
    },
    // updateFatura
    faturaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.fatura.id) {
          return action.payload.fatura;
        }
        return entity;
      });
    },
    // deleteFatura
    faturaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteFaturas
    faturasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
