import * as requestFromServer from "./gestaoFaturasCrud";
import {gestaoFaturasSlice, callTypes} from "./gestaoFaturasSlice";

const {actions} = gestaoFaturasSlice;

export const fetchGestaoFaturas = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.gestaoFaturasFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findGestaoFaturas(queryParams,tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.gestaoFaturasFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gestaoFaturas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGestaoFatura = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.gestaoFaturaFetched({ gestaoFaturaForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGestaoFaturaById(id)
    .then(response => {
        const gestaoFatura = response.data.entities.resultado;
      dispatch(
        actions.gestaoFaturaFetched({ gestaoFaturaForEdit: gestaoFatura })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find gestaoFatura";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGestaoFatura = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGestaoFatura(id)
    .then(response => {
      dispatch(actions.gestaoFaturaDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gestaoFatura";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGestaoFatura = gestaoFaturaForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGestaoFatura(gestaoFaturaForCreation)
    .then(response => {
        const gestaoFatura = response.data.entities;
      dispatch(actions.gestaoFaturaCreated({ gestaoFatura:gestaoFatura }));
    })
    .catch(error => {
      error.clientMessage = "Can't create gestaoFatura";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGestaoFatura = (id,gestaoFatura) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGestaoFatura(id,gestaoFatura)
    .then(() => {
      dispatch(actions.gestaoFaturaUpdated({ gestaoFatura }));
    })
    .catch(error => {
      error.clientMessage = "Can't update gestaoFatura";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGestaoFaturas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGestaoFaturas(ids)
    .then(() => {
      dispatch(actions.gestaoFaturasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gestaoFaturas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaGestaoFaturas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaGestaoFaturas(ids)
      .then(() => {
        dispatch(actions.gestaoFaturasDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};


export const marcarComoLidaGestaoFaturas = (tipoNotificacaoMenu, todos) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaGestaoFaturas(tipoNotificacaoMenu,  todos)
      // .then(() => {
      //   dispatch(actions.gestaoFaturasDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
