import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialTiposOportunidadeState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  tipoOportunidadeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const tiposOportunidadeSlice = createSlice({
  name: "tiposOportunidade",
  initialState: initialTiposOportunidadeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTipoOportunidadeById
    tipoOportunidadeFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      if (action.payload.tipoOportunidadeForEdit && action.payload.tipoOportunidadeForEdit.idTipoOportunidade) {
        action.payload.tipoOportunidadeForEdit.endCEPTipoOportunidade = aplicarMascaraCEP(action.payload.tipoOportunidadeForEdit.endCEPTipoOportunidade);
      }
      state.tipoOportunidadeForEdit = action.payload.tipoOportunidadeForEdit;
      state.error = null;
    },
    // findTiposOportunidade
    tiposOportunidadeFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTipoOportunidade
    tipoOportunidadeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.tipoOportunidade);
    },
    // updateTipoOportunidade
    tipoOportunidadeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.tipoOportunidade.idTipoOportunidade) {
          return action.payload.tipoOportunidade;
        }
        return entity;
      });
    },
    // deleteTipoOportunidade
    tipoOportunidadeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTiposOportunidade
    tiposOportunidadeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   el => !action.payload.ids.includes(el.id)
      // );
    },
    // tiposOportunidadeUpdateState
    tiposOportunidadeStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
