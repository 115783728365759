import {createSlice} from "@reduxjs/toolkit";
const initialDemandasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  demandaForEdit: undefined,
  lastError: null,
  demandaForReplica:undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const demandasSlice = createSlice({
  name: "demandas",
  initialState: initialDemandasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getDemandaById
    demandaFetched: (state, action) => {
      state.actionsLoading = false;
      state.demandaForEdit = action.payload.demandaForEdit;
      state.error = null;
    },
    demandaReplicaFetched: (state, action) => {
      state.actionsLoading = false;
      state.demandaForReplica = action.payload.demandaForReplica;
      state.error = null;
    },
    // findDemandas
    demandasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createDemanda
    demandaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.demanda);
    },
    // updateDemanda
    demandaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.demanda.id) {
          return action.payload.demanda;
        }
        return entity;
      });
    },
    // deleteDemanda
    demandaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteDemandas
    demandasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
