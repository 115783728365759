/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function DropdownMenu4() {
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover py-5">
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-drop"></i></span>
                    <span className="navi-text">Criar Gestao de Energia Distribuida  Group</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-list-3"></i></span>
                    <span className="navi-text">Lista de  Clientes</span>
                </a>
            </li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-bell-2"></i></span>
                    <span className="navi-text">Balanco Energetico</span>
                </a>
            </li>

            <li className="navi-separator my-3"></li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-magnifier-tool"></i></span>
                    <span className="navi-text">Usuarios</span>
                </a>
            </li>

        </ul>
        {/*end::Navigation*/}

    </>
}
