/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const menu = JSON.parse(localStorage.getItem('menuPlataforma'));

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {menu.map((item) => (
                <>
                    <li
                        data-menu-toggle={layoutProps.menuDesktopToggle}
                        aria-haspopup="true"
                        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>
                        <NavLink className="menu-link menu-toggle" to="/google-material">
                            <span className="menu-text">{item.menuAcesso}</span>
                            <i className="menu-arrow"></i>
                        </NavLink>

                        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                            <ul className="menu-subnav">
                                {
                                    JSON.parse(item.itemMenuAcesso).map((itemMenu) => (
                                        <li
                                            className={`menu-item  ${getMenuItemActive(itemMenu.url)}`}
                                        >
                                            <NavLink className="menu-link" to={itemMenu.url}>
                                                <span className="menu-text">{itemMenu.menu}</span>
                                            </NavLink>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </li>
                </>
            ))}
            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>
}
