import {createSlice} from "@reduxjs/toolkit";

const initialTelemetriaEnergiaState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  telemetriaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const telemetriaEnergiaSlice = createSlice({
  name: "telemetriaEnergia",
  initialState: initialTelemetriaEnergiaState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getHistoricoUCById
    telemetriaFetched: (state, action) => {
      state.actionsLoading = false;
      state.telemetriaForEdit = action.payload.telemetriaForEdit;
      state.error = null;
    },
    // findTelemetriaEnergia
    telemetriaEnergiaFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createHistoricoUC
    telemetriaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.telemetria);
    },
    // updateHistoricoUC
    telemetriaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.telemetria.id) {
          return action.payload.telemetria;
        }
        return entity;
      });
    },
    // deleteHistoricoUC
    telemetriaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTelemetriaEnergia
    telemetriaEnergiaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // telemetriaEnergiaUpdateState
    telemetriaEnergiaStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
