/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useRef, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../../_helpers";
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Offcanvas from 'react-bootstrap/Offcanvas';
import moment from "moment";
import { Formik } from "formik";


import * as actionsGestao from "../../../../../../app/modules/GD/_redux/gestaoFaturas/gestaoFaturasActions";
import * as actionsMercadoLivre from "../../../../../../app/modules/GD/_redux/mercadoLivres/mercadoLivresActions";
import * as actionsAdministrativos from "../../../../../../app/modules/GD/_redux/administrativos/administrativosActions";
import * as actionsCrms from "../../../../../../app/modules/GD/_redux/crms/crmsActions";
import { getSelectPeriodo, getSelectTelas, getSelectCliente } from "../../../../../../app/lib/selectAPI";
import { Select, Input } from "../../../../../_partials/controls";


const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

export const CRM = ({
}) => {

    const dispatch = useDispatch();

    const [dropdownsNaoLido, setDropdownsNaoLido] = useState(true);
    const [dropdownsLido, setDropdownsLido] = useState(true);
    const [dropdownsLidoQtde, setDropdownsLidoQtde] = useState(true);
    const [dropdownsFiltro, setDropdownsFiltro] = useState(false);

    const [crmLido, setCRMLido] = useState(0);
    const [crmNaoLido, setCRMNaoLido] = useState(0);
    const [crmConcluido, setCRMConcluido] = useState(0);
    const [crmErro, setCRMErro] = useState(0);
    const [crmSoma, setCRMSoma] = useState(0);



    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(uiService.config, "extras.notifications.layout") ===
                "offcanvas",
        };
    }, [uiService]);

    const { entitiesCRMS } = useSelector(
        (state) => ({
            entitiesCRMS: state.crms.entities
        }),
        shallowEqual
    );

    var contadorNaoLidoCRM = 0;
    var contadorLidoCRM = 0;
    var contadorConcluidoCRM = 0;
    var contadorErroCRM = 0;

    useEffect(() => {
        if (entitiesCRMS != null) {
            entitiesCRMS.map((item) => {
                if (item.situacaoLeitura == 'N') {
                    contadorNaoLidoCRM++;
                }
                if (item.situacaoLeitura == 'L') {
                    contadorLidoCRM++;
                }
                if (item.situacao == 'C') {
                    contadorConcluidoCRM++;
                }
                if (item.situacao == 'E') {
                    contadorErroCRM++;
                }
            })
            setCRMNaoLido(contadorNaoLidoCRM);
            setCRMLido(contadorLidoCRM);
            setCRMConcluido(contadorConcluidoCRM);
            setCRMErro(contadorErroCRM);
            setCRMSoma(contadorConcluidoCRM + contadorErroCRM);
        } else {
            setCRMNaoLido(0);
            setCRMLido(0);
            setCRMConcluido(0);
            setCRMErro(0);
            setCRMSoma(0);
        }
    }, [entitiesCRMS])



    const handleMarcar = (e, tela) => {
        dispatch(actionsGestao.marcarComoLidaGestaoFaturas(tela));
    }


    const [clienteCombo, setClienteCombo] = useState([]);
    const [telaComboGestao, setTelaComboGestao] = useState([]);
    const [telaComboCRM, setTelaComboCRM] = useState([]);

    const deparaNome = (telas) => {
        var opcoesTelaCRM = [];
        opcoesTelaCRM.push({ 'id': null, value: 'Todos' });
        telas.forEach((item) => {

            if (item.idMenuAcesso == 5) {
                opcoesTelaCRM.push({ id: item.id, value: `CRM - ${item.linkAcesso}` })
            }
        })
        setTelaComboCRM(opcoesTelaCRM);
    }




    useEffect(() => {
        const queryParams = {
            filter: {
                periodo: null,
                data: null,
                idCliente: null,
                idLinkAcesso: null,
                tipoNotificacao: null,
                tipoNotificacaoMenu: null,
                idUsuario: null
            },

            sortOrder: "asc",
            sortField: "VINCode",
            pageNumber: 1,
            pageSize: 30
        };
        dispatch(actionsCrms.fetchCRMS(queryParams, "T"));
    }, [])

    useEffect(() => {
        getSelectCliente(true, false).then(function (retorno) {
            setClienteCombo(retorno);
        });
        getSelectTelas(true, false).then(function (retorno) {
            deparaNome(retorno)
        });
    }, [])

    const applyFilter = (values) => {
        const queryParams = {
            filter: {
                periodo: values.periodo,
                data: values.data,
                idCliente: values.idCliente,
                idLinkAcesso: values.idLinkAcesso,
                tipoNotificacao: values.situacao,
                tipoNotificacaoMenu: values.tipoNotificacaoMenu,
                idUsuario: values.idUsuario,
                situacao: values.situacao
            },

            sortOrder: "asc",
            sortField: "VINCode",
            pageNumber: 1,
            pageSize: 30
        };
        dispatch(actionsCrms.marcarComoLidaCRM(queryParams, values.todasNotificacaoApenasMinha))
    }

    const queryParams = {
        filter: {
            periodo: null,
            data: null,
            idCliente: null,
            idLinkAcesso: null,
            tipoNotificacao: null,
            tipoNotificacaoMenu: null,
            idUsuario: null
        },

        sortOrder: "asc",
        sortField: "VINCode",
        pageNumber: 1,
        pageSize: 30
    };



    const handleMarcarCRM = (e) => {
        dispatch(actionsCrms.marcarComoLidaCRM('5')).then(() => {
            dispatch(actionsCrms.fetchCRMS(queryParams, "T"));
        });
    }


    const [ucPaginar, setUCPaginar] = useState([]);
    function dividirPorVirgula(jsonStr, id) {
        try {
            const parsedJson = JSON.parse(jsonStr);
            const ucValues = parsedJson['uc sem produto'].split(',');
            const result = ucValues.map((uc) => ({ uc, id }));
            return result;
        } catch (error) {
            console.error('Erro ao processar o JSON:', error);
            return [];
        }
    }

    useEffect(() => {
        if (entitiesCRMS != null) {
            var resultado = []
            entitiesCRMS.map((item) => {
                resultado.push(dividirPorVirgula(item.notificacao, item.id))
            })
            setUCPaginar(resultado);
        } else {
            setUCPaginar([])
        }
    }, [entitiesCRMS, dispatch])


    function formatarData(data) {
        const agora = moment();
        const evento = moment(data);

        const diferencaMinutos = agora.diff(evento, 'minutes');
        const diferencaHoras = agora.diff(evento, 'hours');
        const diferencaDias = agora.diff(evento, 'days');
        const diferencaSemanas = agora.diff(evento, 'weeks');

        if (diferencaMinutos < 60) {
            return `${diferencaMinutos} minutos`;
        } else if (diferencaHoras < 24) {
            return `${diferencaHoras} horas`;
        } else if (diferencaDias < 7) {
            return `${diferencaDias} dias`;
        } else {
            return `${diferencaSemanas} semanas`;
        }
    }


    return (
        <>
            <div className="d-flex justify-content-between mt-5 align-items-center">
                <div style={{ 'marginLeft': '22px' }}>
                    <h3 style={{ 'color': 'rgb(156 163 175)', 'font-weight': '600' }}>
                        Não Lidas - <span style={{ 'color': 'white' }}>{crmNaoLido}</span>
                    </h3>
                </div>
                <div className="d-flex align-items-center">
                    <h3 style={{ 'color': 'white', 'font-weight': '600' }}>
                        <button
                            id="menu-button" aria-expanded="true" aria-haspopup="true" className="btn btn-primary" style={{ 'marginRight': '15px' }}>
                            <div className="d-flex align-items-center ">
                                <div className="d-flex flex-column align-items-start" onClick={(e) => handleMarcarCRM(e)}>
                                    <span style={{ 'font-weight': '700' }} >Marcar todas como lida</span>
                                </div>
                            </div>
                        </button>
                    </h3>
                    {/* <button onClick={() => setDropdownsFiltro(dropdownsFiltro !== true ? true : false)}
                        type="button"
                        id="menu-button" aria-expanded="true" aria-haspopup="true" className="btn btn-primary mb-1 ml-1 mr-1">
                        <div className="rounded ml-2">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 1C3 0.447715 2.55228 0 2 0C1.44772 0 1 0.447715 1 1V8.26756C0.402199 8.61337 0 9.25972 0 10C0 10.7403 0.402199 11.3866 1 11.7324V13C1 13.5523 1.44772 14 2 14C2.55228 14 3 13.5523 3 13V11.7324C3.5978 11.3866 4 10.7403 4 10C4 9.25972 3.5978 8.61337 3 8.26756V1Z" fill="#FFFFFF" />
                                <path d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V2.26756C6.4022 2.61337 6 3.25972 6 4C6 4.74028 6.4022 5.38663 7 5.73244V13C7 13.5523 7.44772 14 8 14C8.55229 14 9 13.5523 9 13V5.73244C9.5978 5.38663 10 4.74028 10 4C10 3.25972 9.5978 2.61337 9 2.26756V1Z" fill="#FFFFFF" />
                                <path d="M14 0C14.5523 0 15 0.447715 15 1V8.26756C15.5978 8.61337 16 9.25972 16 10C16 10.7403 15.5978 11.3866 15 11.7324V13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13V11.7324C12.4022 11.3866 12 10.7403 12 10C12 9.25972 12.4022 8.61337 13 8.26756V1C13 0.447715 13.4477 0 14 0Z" fill="#FFFFFF" />
                            </svg>

                        </div>
                    </button> */}
                    <button onClick={() => setDropdownsNaoLido(dropdownsNaoLido !== true ? true : false)}
                        type="button"
                        id="menu-button" aria-expanded="true" aria-haspopup="true" className="btn btn-primary mb-1 ml-1 mr-1">
                        <div className="rounded ml-2">
                            <svg width="7" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#ffffff" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>

            {/* 
            <div style={dropdownsFiltro === true ? {} : { display: 'none' }}>
                <Formik
                    initialValues={{
                        periodo: "",
                        data: "",
                        idCliente: "",
                        idLinkAcesso: "",
                        tipoNotificacao: "",
                        situacao: "",
                        todasNotificacaoApenasMinha: "T",
                        tipoNotificacaoMenu: '1',
                    }}
                    onSubmit={values => {
                        if (values.periodo == "") {
                            values.periodo = null
                        }
                        if (values.idCliente == "" || values.idCliente == null) {
                            values.idCliente = null
                        } else {
                            values.idCliente = parseInt(values.idCliente)
                        }
                        if (values.idLinkAcesso == "" || values.idLinkAcesso == null) {
                            values.idLinkAcesso = null
                        } else {
                            values.idLinkAcesso = parseInt(values.idLinkAcesso)
                        }
                        if (values.tipoNotificacao == "") {
                            values.tipoNotificacao = null
                        }
                        if (values.data == "") {
                            values.data = null
                        }
                        if (values.situacao == "") {
                            values.situacao = null
                        }
                        applyFilter(values);
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        resetForm
                    }) => (
                        <div
                            className="origin-top-right absolute bg-white rounded-lg mt-5 px-5 py-5 shadow-md" style={{ 'width': '340px', 'marginLeft': '220px', 'position': 'absolute', 'margin-left': '450px' }}
                        >
                            <div class="" >
                                <div className="d-flex justify-content-between">
                                    <h3 className="text-gray-600 text-base	 font-bold">
                                        Filtros
                                    </h3>
                                    <div>
                                        <button type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                                resetForm();
                                                handleSubmit();
                                            }}
                                        >
                                            <h3
                                                className="text-gray-400 text-xs font-bold underline">
                                                Limpar Filtros
                                            </h3>
                                        </button>
                                    </div>
                                    <div onClick={() => setDropdownsFiltro(false)}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.863579 0.86361C1.21505 0.512138 1.7849 0.512138 2.13637 0.86361L5.99998 4.72721L9.86358 0.86361C10.2151 0.512138 10.7849 0.512138 11.1364 0.86361C11.4878 1.21508 11.4878 1.78493 11.1364 2.1364L7.27277 6.00001L11.1364 9.86361C11.4878 10.2151 11.4878 10.7849 11.1364 11.1364C10.7849 11.4879 10.2151 11.4879 9.86358 11.1364L5.99998 7.2728L2.13637 11.1364C1.7849 11.4879 1.21505 11.4879 0.863579 11.1364C0.512108 10.7849 0.512108 10.2151 0.863579 9.86361L4.72718 6.00001L0.863579 2.1364C0.512108 1.78493 0.512108 1.21508 0.863579 0.86361Z" fill="#52525B" />
                                        </svg>
                                    </div>
                                </div>

                                <div>
                                    <div class="d-flex align-items-center mb-4 flex-column mt-4">
                                        <div style={{ 'marginRight': '100px' }}>
                                            <input id="default-radio-1"
                                                type="radio"
                                                value="T"
                                                onChange={e => {
                                                    setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                    handleSubmit();
                                                }}
                                                name="default-radio"
                                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-1" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Todas as notificações</label>
                                        </div>
                                        <div style={{ 'marginRight': '59px' }}>
                                            <input
                                                id="default-radio-2"
                                                type="radio"
                                                value="A"
                                                onChange={e => {
                                                    setFieldValue("todasNotificacaoApenasMinha", e.target.value);
                                                    handleSubmit();
                                                }}
                                                name="default-radio"
                                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-radio-2" class="ml-2 text-xs	 font-medium text-gray-900 dark:text-gray-300">Apenas minhas notificações</label>
                                        </div>
                                    </div>
                                </div>


                                <div className="container">
                                    <div className="mt-1 mb-1 row">
                                        <div className="col">
                                            <Select
                                                type="text"
                                                component={Input}
                                                label="Período"
                                                name="periodo"
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    setFieldValue("periodo", e.target.value);
                                                    handleSubmit();
                                                }}
                                                value={values.periodo}
                                            >
                                                {getSelectPeriodo(true).map((item) => (
                                                    <option value={item.id}>
                                                        {item.valor}
                                                    </option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="mt-1 mb-1 row">
                                        <div className="col">
                                            <Select
                                                name="idCliente"
                                                label="Clientes"
                                                placeholder="Filtrar por Cliente"
                                                onChange={(e) => {
                                                    setFieldValue("idCliente", e.target.value);
                                                    handleSubmit();
                                                }}
                                                onBlur={handleBlur}
                                                value={values.idCliente}
                                            >
                                                {clienteCombo.map((item) => (
                                                    <option value={item.idCliente}>
                                                        {item.nomeCliente}
                                                    </option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="mt-1 mb-1 row">
                                        <div className="col">
                                            <Select
                                                name="idLinkAcesso"
                                                label="Telas"
                                                placeholder="Filtrar por Cliente"
                                                onChange={(e) => {
                                                    setFieldValue("idLinkAcesso", e.target.value);
                                                    handleSubmit();
                                                }}
                                                onBlur={handleBlur}
                                                value={values.idLinkAcesso}
                                            >
                                                {telaComboCRM.map((item) => (
                                                    <option value={item.id}>
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div class="d-flex align-items-center mb-4">
                                    <input id="default-checkbox"
                                        type="checkbox"
                                        onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                        }}
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <div className="ml-3 mr-3">
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.5" cy="3" r="2.5" fill="#A1A1AA" />
                                        </svg>
                                    </div>
                                    <div className="align-items-center">
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Selecionar Todos</label>
                                        <span style={{ 'maringLeft': '90px' }}>{crmSoma}</span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-4 ">
                                    <input id="default-checkbox"
                                        type="checkbox"
                                        value="C"
                                        onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                        }}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <div className="ml-3 mr-3">
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.5" cy="3" r="2.5" fill="#22C55E" />
                                        </svg>
                                    </div>
                                    <div className="align-items-center">
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concluído</label>
                                        <span style={{ 'maringLeft': '90px' }}>{crmConcluido}</span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-4">
                                    <input
                                        id="default-checkbox"
                                        type="checkbox" value="E"
                                        onChange={(e) => {
                                            setFieldValue("situacao", e.target.value);
                                            handleSubmit();
                                        }}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <div className="ml-3 mr-3">
                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.5" cy="3" r="2.5" fill="#EF4444" />
                                        </svg>
                                    </div>
                                    <div className="align-items-center">
                                        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Erro</label>
                                        <span style={{ 'maringLeft': '90px' }}>{crmErro}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            </div> */}

            <div style={dropdownsNaoLido === true ? {} : { display: 'none' }}>
                {entitiesCRMS && entitiesCRMS.map((item) => {
                    return (
                        <>
                            {item.situacaoLeitura == 'N' && (
                                <>
                                    <div className="d-flex flex-row justify-content-between align-items-center mt-5 mb-5">
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                                    {item.notificacaoSucesso == 'E' ?
                                                        (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                            <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                        </svg>)
                                                        : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                            <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                        </svg>
                                                        )}
                                                </div>
                                                <label
                                                    style={{ 'color': 'white', 'fontSize': 'x-large', 'marginLeft': '11px' }}
                                                >U.C(s) com falta de produto - {item.id}</label>
                                            </div>
                                            {ucPaginar.length > 0 ? (
                                                <>
                                                    <div>
                                                        {ucPaginar.map((item3, i) => {
                                                            return (
                                                                item3.map((item4) => {
                                                                    return (
                                                                        <>
                                                                            {item.id == item4.id && (
                                                                                <ul style={{ 'listStyle': 'none', 'padding': 0 }}>
                                                                                    <li
                                                                                        // style={{ 'marginRight': '122px', 'fontSize': 'x-large' }}
                                                                                        style={{ 'textAlign': 'center', 'paddingRight': '20px', 'marginBottom': '10px', 'fontSize': 'x-large' }}
                                                                                    >
                                                                                        U.C: {item4.uc}
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>

                                                                    )
                                                                })
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            )

                                                :
                                                <>
                                                </>
                                            }
                                            {/* <label className="d-flex align-items-center">
                                                    <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                                                        {item.situacao == 'E' ? (
                                                            <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignContent': 'center', 'marginLeft': '10px' }}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                                                            </svg></div>
                                                        ) :
                                                            <>
                                                            </>
                                                        } </h3>
                                                </label> */}


                                            <div className="d-flex" style={{ 'marginLeft': '68px' }}>
                                                <div>
                                                    <label style={{ 'color': 'white' }}>
                                                        {/* {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''} */}
                                                        há {item.dataNotificacao != null ? formatarData(item.dataNotificacao) : ''}
                                                    </label>
                                                </div>
                                                <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'marginLeft': '10px', 'marginRight': '10px', 'marginBottom': '5px' }}>
                                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <label>
                                                        {/* {item.nomeCliente} */}
                                                        Mato Grosso Energia
                                                    </label>
                                                </div>
                                                {/* <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'marginLeft': '10px', 'marginRight': '10px', 'marginBottom': '5px' }}>
                                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <label className="">
                                                        {item.nomeUsuario}
                                                    </label>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* <div>
                                            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
                                            </svg>

                                        </div> */}
                                    </div>
                                </>
                            )}
                        </>
                    )
                })}
            </div>

            <div className="d-flex justify-content-between mt-5 align-items-center">
                <div style={{ 'marginLeft': '22px' }}>
                    <h3 style={{ 'color': 'rgb(156 163 175)', 'font-weight': '600' }}>
                        Lidas - <span style={{ 'color': 'white' }}>{crmLido}</span>
                    </h3>
                </div>

                <h3 style={{ 'color': 'rgb(156 163 175)', 'font-weight': '600' }}>
                    <button
                        onClick={() => setDropdownsLido(dropdownsLido !== true ? true : false)}
                        type="button"
                        id="menu-button" aria-expanded="true" aria-haspopup="true" className="btn btn-primary">
                        <div className="d-flex align-items-center">
                            {/* <div className="flex flex-column align-items-start">
                  <span className="text-sm text-gray-400 font-bold">Marcar todas como lida</span>
                </div> */}
                            <div className="rounded-full mr-4">
                                <svg width="7" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.792893C0.683417 0.402369 1.31658 0.402369 1.70711 0.792893L5 4.08579L8.29289 0.792893C8.68342 0.402369 9.31658 0.402369 9.70711 0.792893C10.0976 1.18342 10.0976 1.81658 9.70711 2.20711L5.70711 6.20711C5.31658 6.59763 4.68342 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893Z" fill="#ffffff" />
                                </svg>

                            </div>
                        </div>
                    </button>
                </h3>
            </div>

            <div style={dropdownsLido === true ? {} : { display: 'none' }}>
                {entitiesCRMS && entitiesCRMS.map((item) => {
                    return (
                        <>
                            {item.situacaoLeitura == 'L' && (
                                <>
                                    <div className="d-flex flex-row justify-content-between align-items-center mt-5 mb-5">
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="" style={{ display: 'flex', 'marginLeft': '10px', 'alignContent': 'center' }}>
                                                    {item.notificacaoSucesso == 'E' ?
                                                        (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                            <path d="M19.2389 25.264H15.5709L14.9829 27H12.4769L16.0329 17.172H18.8049L22.3609 27H19.8269L19.2389 25.264ZM18.6229 23.416L17.4049 19.818L16.2009 23.416H18.6229ZM27.1252 17.172C28.1612 17.172 29.0665 17.3773 29.8412 17.788C30.6159 18.1987 31.2132 18.7773 31.6332 19.524C32.0625 20.2613 32.2772 21.1153 32.2772 22.086C32.2772 23.0473 32.0625 23.9013 31.6332 24.648C31.2132 25.3947 30.6112 25.9733 29.8272 26.384C29.0525 26.7947 28.1519 27 27.1252 27H23.4432V17.172H27.1252ZM26.9712 24.928C27.8765 24.928 28.5812 24.6807 29.0852 24.186C29.5892 23.6913 29.8412 22.9913 29.8412 22.086C29.8412 21.1807 29.5892 20.476 29.0852 19.972C28.5812 19.468 27.8765 19.216 26.9712 19.216H25.8372V24.928H26.9712Z" fill="#4169E1" />
                                                        </svg>)
                                                        : (<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="45" height="45" rx="22.5" fill="#E0E7FF" />
                                                            <path d="M22.59 17.172V27H20.196V22.954H16.472V27H14.078V17.172H16.472V21.022H20.196V17.172H22.59ZM26.8662 25.04H31.0942V27H24.1502V25.18L28.3502 19.132H24.1502V17.172H31.0942V18.992L26.8662 25.04Z" fill="#4169E1" />
                                                        </svg>
                                                        )}
                                                </div>
                                                <label
                                                    style={{ 'color': 'white', 'fontSize': 'x-large', 'marginLeft': '11px' }}
                                                >U.C(s) com falta de produto - {item.id}</label>
                                            </div>
                                            {ucPaginar.length > 0 ? (
                                                <>
                                                    <div>
                                                        {ucPaginar.map((item3, i) => {
                                                            return (
                                                                item3.map((item4) => {
                                                                    return (
                                                                        <>
                                                                            {item.id == item4.id && (
                                                                                <ul style={{ 'listStyle': 'none', 'padding': 0 }}>
                                                                                    <li
                                                                                        // style={{ 'marginRight': '122px', 'fontSize': 'x-large' }}
                                                                                        style={{ 'textAlign': 'center', 'paddingRight': '20px', 'marginBottom': '10px', 'fontSize': 'x-large' }}
                                                                                    >
                                                                                        U.C: {item4.uc}
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </>

                                                                    )
                                                                })
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            )

                                                :
                                                <>
                                                </>
                                            }
                                            {/* <label className="d-flex align-items-center">
                <h3 style={{ display: 'flex', 'marginLeft': '10px', 'justifyContent': 'center', 'alignContent': 'center' }}>{item.situacao == 'E' ? 'Erro' : 'Concluído'}  - {item.notificacao}
                    {item.situacao == 'E' ? (
                        <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignContent': 'center', 'marginLeft': '10px' }}><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5ZM7.25 9.5C7.25 9.91421 6.91421 10.25 6.5 10.25C6.08579 10.25 5.75 9.91421 5.75 9.5C5.75 9.08579 6.08579 8.75 6.5 8.75C6.91421 8.75 7.25 9.08579 7.25 9.5ZM6.5 2.75C6.08579 2.75 5.75 3.08579 5.75 3.5V6.5C5.75 6.91421 6.08579 7.25 6.5 7.25C6.91421 7.25 7.25 6.91421 7.25 6.5V3.5C7.25 3.08579 6.91421 2.75 6.5 2.75Z" fill="#A1A1AA" />
                        </svg></div>
                    ) :
                        <>
                        </>
                    } </h3>
            </label> */}


                                            <div className="d-flex" style={{ 'marginLeft': '68px' }}>
                                                <div>
                                                    <label style={{ 'color': 'white' }}>
                                                        {/* {item.dataNotificacao != null ? moment(item.dataNotificacao).startOf('hour').fromNow() : ''} */}
                                                        há {item.dataNotificacao != null ? formatarData(item.dataNotificacao) : ''}
                                                    </label>
                                                </div>
                                                <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'marginLeft': '10px', 'marginRight': '10px', 'marginBottom': '5px' }}>
                                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <label>
                                                        {/* {item.nomeCliente} */}
                                                        Mato Grosso Energia
                                                    </label>
                                                </div>
                                                {/* <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'marginLeft': '10px', 'marginRight': '10px', 'marginBottom': '5px' }}>
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="3" cy="3" r="3" fill="#D4D4D8" />
                </svg>
            </div>
            <div>
                <label className="">
                    {item.nomeUsuario}
                </label>
            </div> */}
                                            </div>
                                        </div>

                                        {/* <div>
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.5" r="5" fill="#4169E1" />
        </svg>

    </div> */}
                                    </div>
                                </>
                            )}

                        </>
                    )
                })}
            </div>
        </>
    )
}