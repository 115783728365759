import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialClientesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  clienteForEdit: undefined,
  lastError: null,
  entitiesClienteUnificar: null,
  totalCountClienteUnificar: 0,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const clientesSlice = createSlice({
  name: "clientes",
  initialState: initialClientesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getClienteById
    clienteFetched: (state, action) => {


      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.clienteForEdit = action.payload.clienteForEdit;
      state.error = null;

    },
    // findClientes
    clientesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.clienteForEdit = undefined;
    },
    clientesUnificarFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesClienteUnificar = entities;
      state.totalCountClienteUnificar = totalCount;
    },
    // createCliente
    clienteCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.cliente);
    },
    // updateCliente
    clienteUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.clienteForEdit = undefined;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.cliente.IdCliente) {
      //     return action.payload.cliente;
      //   }
      //   return entity;
      // });
    },
    // deleteCliente
    clienteDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteClientes
    clientesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // clientesUpdateState
    clientesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
