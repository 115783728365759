import React from 'react'
import MultiCrops from 'react-multi-crops'
import img from './v1.png'


export class MapearFatura extends React.Component {
    state = {
        coordinates: [
            {
                x: 178, y: 91, width: 158, height: 132, id: 'SJxb6YpuG',
            },
            {
                x: 436, y: 97, width: 170, height: 168, id: 'SJMZ6YTdf',
            },
        ],
    }

    changeCoordinate = (coordinate, index, coordinates) => {
        this.setState({
            coordinates,
        })
    }
    deleteCoordinate = (coordinate, index, coordinates) => {
        this.setState({
            coordinates,
        })
    }
    render() {
        return (
<>
                <MultiCrops
                    src={img}
                    width={1000}
                    coordinates={this.state.coordinates}
                    // onDrag={this.changeCoordinate}
                    // onResize={this.changeCoordinate}
                    // onDraw={this.changeCoordinate}
                    onChange={this.changeCoordinate}
                    onDelete={this.deleteCoordinate}
                    // onLoad={e => console.log(e.target.height, e.target.width)}
                />
                </>
        )
    }
}
