import {createSlice} from "@reduxjs/toolkit";
const initialConfiguracaosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  configuracaoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const configuracaosSlice = createSlice({
  name: "configuracaos",
  initialState: initialConfiguracaosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getConfiguracaoById
    configuracaoFetched: (state, action) => {
      state.listLoading = false;
      state.configuracaoForEdit = action.payload.configuracaoForEdit;
      state.error = null;
    },
    // findConfiguracaos
    configuracaosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createConfiguracao
    configuracaoCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.configuracao);
    },
    // updateConfiguracao
    configuracaoUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.configuracao.id) {
          return action.payload.configuracao;
        }
        return entity;
      });
    },
    // deleteConfiguracao
    configuracaoDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteConfiguracaos
    configuracaosDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
