import axios from "axios";
import FormData from 'form-data';
import { config } from "./../../../../Config";
export const PRODUCTS_URL = config.url.apiEndPoint + "/gd";
export const IMPORTACAO_URL = config.url.apiEndPoint+  "/importacaoArquivo";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/gd";
// CREATE =>  POST: add a new gd to the server
export function createGd(gd) {
  console.log(gd);
  const request = {
    method: 'POST',
    url: `${PRODUCTS_URL}/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: gd,
  };

  return axios(request);
}

// READ
export function getAllGds() {
  return axios.get(PRODUCTS_URL);
}

export function getGdById(gdId) {
  return axios.get(PRODUCTS_URL + `/${gdId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGds(queryParams) {


  const request = {
    method: 'POST',
    url: PRODUCTS_URL + `/list`,
    data: queryParams,
  };

  return axios(request);
}
export function exportExcelBoletim(queryParams) {


  const request = {
    method: 'POST',
    url: PRODUCTS_URL + `/excel/boletim`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the procuct on the server
export function updateGd(values) {
  values.IdGD = values.id;
  values.idCliente = "" + values.idCliente;
  const request = {
    method: 'PUT',
    url: PRODUCTS_URL + `/alterar`,
    data: values,
  };

  return axios(request);
}

// UPDATE Status
export function updateStatusForGds(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForGds`, {
    ids,
    status
  });
}

// DELETE => delete the gd from the server
export function deleteGd(gdId) {
  return axios.delete(`${PRODUCTS_URL}/${gdId}`);
}

// DELETE Gds by ids
export function deleteGds(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteGds`, { ids });
}

export function ObterGDData(gdId, data) {
  return axios.get(PRODUCTS_URL + `/${gdId}/${data}`);
}

export function createImportacaoArquivoRateio(queryParams, productId) {

  const request = {
    method: 'POST',
    url: IMPORTACAO_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}
export function getUCRateioNumerosById(ucRateiosId,idArquivoImportacao) {
  return axios.get(`${SPECIFICATIONS_URL}/ucsNumero/${ucRateiosId}/${idArquivoImportacao}`);
}

export function rodarImportacaoArquivoRateio(queryParams, productId) {

  const request = {
    method: 'POST',
    url: PRODUCTS_URL+`/rodarplanilha`,
    headers: {
      'Content-Type': 'application/json'
    },
  };

  return axios(request);
}