import {createSlice} from "@reduxjs/toolkit";

const initialUsuarioClientesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  acessoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usuarioClientesSlice = createSlice({
  name: "usuarioClientes",
  initialState: initialUsuarioClientesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUsuarioClienteById
    usuarioClienteFetched: (state, action) => {

      state.actionsLoading = false;
      state.usuarioForEdit = action.payload.usuarioCliente;
      state.error = null;
    },
    // findUsuarioClientes
    usuarioClientesFetched: (state, action) => {

      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUsuarioCliente
    usuarioClienteCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // console.log(action);
      // state.entities.push(action.payload.acessoForEdit);
    },
    // updateUsuarioCliente
    usuarioClienteUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.idUsuario === action.payload.acessoForEdit.idUsuario) {
          return action.payload.acessoForEdit;
        }
        return entity;
      });
    },
    // deleteUsuarioCliente
    usuarioClienteDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUsuarioClientes
    usuarioClientesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // usuarioClientesUpdateState
    usuarioClientesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
