import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useGdsUIContext } from "../GdsUIContext";

const prepareFilter = (queryParams, values) => {
  const { situacao,  nomeGd ,tipoGd,cnpjCPF} = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.situacao = situacao !== "" ? +situacao : undefined;
  filter.cnpjCPF = cnpjCPF 
  filter.nomeGd = nomeGd;

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function GdsFilter({ listLoading }) {
  // Gds UI Context
  const gdsUIContext = useGdsUIContext();
  const gdsUIProps = useMemo(() => {
    return {
      setQueryParams: gdsUIContext.setQueryParams,
      queryParams: gdsUIContext.queryParams,
    };
  }, [gdsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(gdsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, gdsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      gdsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          situacao: "", // values => All=""/Selling=0/Sold=1
          condition: "", // values => All=""/New=0/Used=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  name="situacao"
                  placeholder="Filter by Status"
                  onChange={(e) => {
                    setFieldValue("situacao", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.situacao}
                >
                  <option value="0">Todos</option>
                  <option value="1">Ativo</option>
                  <option value="2">Inativo</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filtrar</b> por Situacao
                </small>
              </div> */}
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="cnpjCPF"
                  placeholder="CPF / CNPJ"
                  onBlur={handleBlur}
                  value={values.cnpjCPF}
                  onChange={(e) => {
                    setFieldValue("cnpjCPF", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filtrar</b> por CPF/CNPJ
                </small>
              </div>
              <div className="col-lg-3">
                <input
                    type="text"
                    className="form-control"
                    name="nomeGd"
                    placeholder="Nome do Gd"
                    onBlur={handleBlur}
                    value={values.nomeGd}
                    onChange={(e) => {
                      setFieldValue("nomeGd", e.target.value);
                      handleSubmit();
                    }}
                />
                <small className="form-text text-muted">
                  <b>Filtrar</b> por Nome do Gd
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
