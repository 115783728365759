import * as requestFromServer from "./configuracaosCrud";
import {configuracaosSlice, callTypes} from "./configuracaosSlice";

const {actions} = configuracaosSlice;

export const fetchConfiguracaos = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.configuracaosFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findConfiguracaos(queryParams, tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.configuracaosFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find configuracaos";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchConfiguracao = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.configuracaoFetched({ configuracaoForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getConfiguracaoById(id)
    .then(response => {
        const configuracao = response.data.entities.resultado;
      dispatch(
        actions.configuracaoFetched({ configuracaoForEdit: configuracao })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find configuracao";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteConfiguracao = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteConfiguracao(id)
    .then(response => {
      dispatch(actions.configuracaoDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete configuracao";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createConfiguracao = configuracaoForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createConfiguracao(configuracaoForCreation)
    .then(response => {
        const configuracao = response.data.entities;
      dispatch(actions.configuracaoCreated({ configuracao:configuracao }));
    })
    .catch(error => {
      error.clientMessage = "Can't create configuracao";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateConfiguracao = (id,configuracao) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateConfiguracao(id,configuracao)
    .then(() => {
      dispatch(actions.configuracaoUpdated({ configuracao }));
    })
    .catch(error => {
      error.clientMessage = "Can't update configuracao";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteConfiguracaos = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteConfiguracaos(ids)
    .then(() => {
      dispatch(actions.configuracaosDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete configuracaos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaConfiguracaos = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaConfiguracaos(ids)
      .then(() => {
        dispatch(actions.configuracaosDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete configuracaos";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};



export const marcarComoLidaConfiguracao = (tipoNotificacaoMenu) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaConfiguracao(tipoNotificacaoMenu)
      // .then(() => {
      //   dispatch(actions.gestaoFaturasDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
