import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

const locale = {
  localize: {
    month: n => months[n]
  },
  formatLong: {
    date: () => 'MMMM/YYYY'
  }
}

export function formatacaoPortugues() { 

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const days = ['Dom', 'Seg','Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }

  return locale
}

export function DateMesAno({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        dateFormat="MMMM/yyyy"
        disabledKeyboardNavigation
        showMonthYearPicker
        showFullMonthYearPicker
        autoComplete="off"
        showTwoColumnMonthYearPicker
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          if(props.onChange!=null) {
            setTimeout(function() {
              props.onChange(val);
            },100);                               }
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export function DateMesAnoPortugues({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        dateFormat="MMMM/yyyy"
        disabledKeyboardNavigation
        showMonthYearPicker
        locale={locale}
        autoComplete="off"
        showFullMonthYearPicker
        showTwoColumnMonthYearPicker
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          if(props.onChange!=null) {
            setTimeout(function() {
              props.onChange(val);
            },100);                               }
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
