import {createSlice} from "@reduxjs/toolkit";
const initialMercadoLivresState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  mercadoLivreForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const mercadoLivresSlice = createSlice({
  name: "mercadoLivres",
  initialState: initialMercadoLivresState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getMercadoLivreById
    mercadoLivreFetched: (state, action) => {
      state.listLoading = false;
      state.mercadoLivreForEdit = action.payload.mercadoLivreForEdit;
      state.error = null;
    },
    // findMercadoLivres
    mercadoLivresFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createMercadoLivre
    mercadoLivreCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.mercadoLivre);
    },
    // updateMercadoLivre
    mercadoLivreUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.mercadoLivre.id) {
          return action.payload.mercadoLivre;
        }
        return entity;
      });
    },
    // deleteMercadoLivre
    mercadoLivreDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteMercadoLivres
    mercadoLivresDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
