import {createSlice} from "@reduxjs/toolkit";
const initialGestaoFaturasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  gestaoFaturaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const gestaoFaturasSlice = createSlice({
  name: "gestaoFaturas",
  initialState: initialGestaoFaturasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getGestaoFaturaById
    gestaoFaturaFetched: (state, action) => {
      state.listLoading = false;
      state.gestaoFaturaForEdit = action.payload.gestaoFaturaForEdit;
      state.error = null;
    },
    // findGestaoFaturas
    gestaoFaturasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createGestaoFatura
    gestaoFaturaCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.gestaoFatura);
    },
    // updateGestaoFatura
    gestaoFaturaUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.gestaoFatura.id) {
          return action.payload.gestaoFatura;
        }
        return entity;
      });
    },
    // deleteGestaoFatura
    gestaoFaturaDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteGestaoFaturas
    gestaoFaturasDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
