import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new mercadoLivres to the server

export function createMercadoLivre(mercadoLivre) {
  mercadoLivre.idDistribuidora = parseInt(mercadoLivre.idDistribuidora);
  mercadoLivre.idCliente = parseInt(mercadoLivre.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:mercadoLivre,
  };

  return axios(request);

}
// READ
// Server should return filtered mercadoLivres by productId
export function getAllProductMercadoLivresByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getMercadoLivreById(mercadoLivreId) {
  return axios.get(`${SPECIFICATIONS_URL}/${mercadoLivreId}`);
}

// Server should return sorted/filtered mercadoLivres and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/mercadoLivresfind/{productId}'. Should be 'api/mercadoLivres/find/{productId}'!!!
export function findMercadoLivres(queryParams, todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "2";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the mercadoLivre
export function updateMercadoLivre(id,mercadoLivre) {
  mercadoLivre.IdMercadoLivre =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: mercadoLivre,
  };
  return axios(request);
}

// DELETE => delete the mercadoLivre
export function deleteMercadoLivre(mercadoLivreId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${mercadoLivreId}`);
}

export function deleteMercadoLivres(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaMercadoLivres(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}

export function marcarComoLidaMercadoLivre(tipoNotificacaoMenu) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas: '' },
  };

  return axios(request);
}
