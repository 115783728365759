import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialDownloadFaturasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  downloadFaturaForEdit: undefined,
  qtdeFaturasBaixar: 0,
  lastError: null,
  urlDownload: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const downloadFaturasSlice = createSlice({
  name: "downloadFaturas",
  initialState: initialDownloadFaturasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getDownloadFaturaById
    downloadFaturaFetched: (state, action) => {


      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.downloadFaturaForEdit = action.payload.downloadFaturaForEdit;
      state.error = null;

    },
    faturasBaixarDownloadFetched: (state, action) => {
      state.actionsLoading = false;
      state.urlDownload = action.payload.urlDownload;
      state.error = null;
    },
    faturasBaixarFetched: (state, action) => {
      state.actionsLoading = false;
      state.qtdeFaturasBaixar = action.payload.qtdeFaturasBaixar;
      state.error = null;
    },
    // findDownloadFaturas
    downloadFaturasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createDownloadFatura
    downloadFaturaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.downloadFatura);
    },
    // updateDownloadFatura
    downloadFaturaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.downloadFatura.id) {
          return action.payload.downloadFatura;
        }
        return entity;
      });
    },
    // deleteDownloadFatura
    downloadFaturaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteDownloadFaturas
    downloadFaturasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // downloadFaturasUpdateState
    downloadFaturasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
