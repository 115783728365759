import React from "react";
import { useField, useFormikContext } from "formik";
import NumberFormat from 'react-number-format';


export function PercentualFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            <NumberFormat thousandSeparator={true} suffix={' %'}
                displayType={'text'}
                decimalSeparator="."
                decimalScale={2}
                thousandSeparator=","
                value={cellContent}
            />
        </>
    );
}

export function PercentualFormatacaoRateio(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            <NumberFormat thousandSeparator={true} suffix={' %'}
                displayType={'text'}
                decimalSeparator="."
                decimalScale={6}
                thousandSeparator=","
                value={cellContent}
            />
        </>
    );
}
