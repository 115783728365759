import {createSlice} from "@reduxjs/toolkit";
const initialMailBoxsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  mailBoxForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const mailBoxsSlice = createSlice({
  name: "mailBoxs",
  initialState: initialMailBoxsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMailBoxById
    mailBoxFetched: (state, action) => {
      state.actionsLoading = false;
      state.mailBoxForEdit = action.payload.mailBoxForEdit;
      state.error = null;
    },
    // findMailBoxs
    mailBoxsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createMailBox
    mailBoxCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.mailBox);
    },
    // updateMailBox
    mailBoxUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.mailBox.id) {
          return action.payload.mailBox;
        }
        return entity;
      });
    },
    // deleteMailBox
    mailBoxDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteMailBoxs
    mailBoxsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
