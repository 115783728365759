import * as requestFromServer from "./mercadoLivresCrud";
import {mercadoLivresSlice, callTypes} from "./mercadoLivresSlice";

const {actions} = mercadoLivresSlice;

export const fetchMercadoLivres = (queryParams, tipo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  // if (!productId) {
  //   return dispatch(
  //     actions.mercadoLivresFetched({ totalCount: 0, entities: null })
  //   );
  // }

  return requestFromServer
    .findMercadoLivres(queryParams, tipo)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.mercadoLivresFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find mercadoLivres";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMercadoLivre = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.mercadoLivreFetched({ mercadoLivreForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMercadoLivreById(id)
    .then(response => {
        const mercadoLivre = response.data.entities.resultado;
      dispatch(
        actions.mercadoLivreFetched({ mercadoLivreForEdit: mercadoLivre })
      );
    })
    .catch(error => {
      error.clientMessage = "Can't find mercadoLivre";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteMercadoLivre = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMercadoLivre(id)
    .then(response => {
      dispatch(actions.mercadoLivreDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete mercadoLivre";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createMercadoLivre = mercadoLivreForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMercadoLivre(mercadoLivreForCreation)
    .then(response => {
        const mercadoLivre = response.data.entities;
      dispatch(actions.mercadoLivreCreated({ mercadoLivre:mercadoLivre }));
    })
    .catch(error => {
      error.clientMessage = "Can't create mercadoLivre";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMercadoLivre = (id,mercadoLivre) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMercadoLivre(id,mercadoLivre)
    .then(() => {
      dispatch(actions.mercadoLivreUpdated({ mercadoLivre }));
    })
    .catch(error => {
      error.clientMessage = "Can't update mercadoLivre";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteMercadoLivres = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMercadoLivres(ids)
    .then(() => {
      dispatch(actions.mercadoLivresDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete mercadoLivres";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const ativaMercadoLivres = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .ativaMercadoLivres(ids)
      .then(() => {
        dispatch(actions.mercadoLivresDeleted({ ids }));
      })
      .catch(error => {
        error.clientMessage = "Can't delete mercadoLivres";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};



export const marcarComoLidaMercadoLivre = (tipoNotificacaoMenu) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      .marcarComoLidaMercadoLivre(tipoNotificacaoMenu)
      // .then(() => {
      //   dispatch(actions.gestaoFaturasDeleted({ tipoNotificacaoMenu }));
      // })
      .catch(error => {
        error.clientMessage = "Can't delete gestaoFaturas";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
