import { createSlice } from "@reduxjs/toolkit";

const initialCobrancasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  cobrancaForEdit: undefined,
  cobrancaBaixaPagamentoManualForEdit: undefined,
  cobrancaNegociacaoFinanceiraForEdit: undefined,
  cobrancaClienteIGreenForEdit: undefined,
  lastError: null,
  entitiesNegociacao: null,
  totalCountNegociacao: 0,
  clienteCadastrar: []
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const cobrancasSlice = createSlice({
  name: 'cobrancas',
  initialState: initialCobrancasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callTypes === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    cobrancaFetched: (state, action) => {
      state.listLoading = false;
      state.cobrancaForEdit = action.payload.cobrancaForEdit;
      state.error = null;
    },
    cobrancaClienteIGreenFetched: (state, action) => {
      state.listLoading = false;
      state.cobrancaClienteIGreenForEdit = action.payload.cobrancaClienteIGreenForEdit;
      state.error = null;
    },
    cobrancaBaixaPagamentoManualFetched: (state, action) => {
      state.listLoading = false;
      state.cobrancaBaixaPagamentoManualForEdit = action.payload.cobrancaBaixaPagamentoManualForEdit;
      state.error = null;
    },
    cobrancaNegociacaoFinanceiraFetched: (state, action) => {
      state.listLoading = false;
      state.cobrancaNegociacaoFinanceiraForEdit = action.payload.cobrancaNegociacaoFinanceiraForEdit;
      state.error = null;
    },
    cobrancaNegociacaoFinanceirasFetched: (state, action) => {

      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesNegociacao = entities;
      state.totalCountNegociacao = totalCount;
    },
    cobrancasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    cobrancaCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // console.log(action);
      // state.entities.push(action.payload.cobranca);
    },
    cobrancaParcelamentoCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      // console.log(action);
      // state.entities.push(action.payload.cobranca);
    },
    cobrancaUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.idCobranca === action.payload.cobranca.idCobranca) {
      //     return action.payload.cobranca;
      //   }
      //   return entity;
      // });
    },
    cobrancaClientePaginar: (state, action) => {
      console.log(action.payload.cobranca)
      state.error = null;
      state.listLoading = false;
      state.clienteCadastrar = action.payload.cobranca;
    },
    cobrancaDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id === action.payload.id);
    },
    cobrancasStatusUpdated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
