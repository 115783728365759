import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialConfigurarSegmentosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  configurarSegmentoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const configurarSegmentosSlice = createSlice({
  name: "configurarSegmentos",
  initialState: initialConfigurarSegmentosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getConfigurarSegmentoById
    configurarSegmentoFetched: (state, action) => {
      state.actionsLoading = false;
      // APLICAR A MASCARA
      if (action.payload.configurarSegmentoForEdit && action.payload.configurarSegmentoForEdit.idConfigurarSegmento) {
        action.payload.configurarSegmentoForEdit.endCEPConfigurarSegmento = aplicarMascaraCEP(action.payload.configurarSegmentoForEdit.endCEPConfigurarSegmento);
      }
      state.configurarSegmentoForEdit = action.payload.configurarSegmentoForEdit;
      state.error = null;
    },
    // findConfigurarSegmentos
    configurarSegmentosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createConfigurarSegmento
    configurarSegmentoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.configurarSegmento);
    },
    // updateConfigurarSegmento
    configurarSegmentoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.configurarSegmento.idConfigurarSegmento) {
          return action.payload.configurarSegmento;
        }
        return entity;
      });
    },
    // deleteConfigurarSegmento
    configurarSegmentoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteConfigurarSegmentos
    configurarSegmentosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   el => !action.payload.ids.includes(el.id)
      // );
    },
    // configurarSegmentosUpdateState
    configurarSegmentosConfigurarSegmentoUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, configurarSegmento } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.configurarSegmento = configurarSegmento;
        }
        return entity;
      });
    }
  }
});
