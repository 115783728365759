import {createSlice} from "@reduxjs/toolkit";
const initialCRMSState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  crmForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const crmsSlice = createSlice({
  name: "crms",
  initialState: initialCRMSState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    // getCRMById
    crmFetched: (state, action) => {
      state.listLoading = false;
      state.crmForEdit = action.payload.crmForEdit;
      state.error = null;
    },
    // findCRMS
    crmsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCRM
    crmCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities.push(action.payload.crm);
    },
    // updateCRM
    crmUpdated: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.crm.id) {
          return action.payload.crm;
        }
        return entity;
      });
    },
    // deleteCRM
    crmDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCRMS
    crmsDeleted: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
