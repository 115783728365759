import React, {useMemo} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_layout/_partials/controls";
import { GdsFilter } from "./gds-filter/GdsFilter";
import { GdsTable } from "./gds-table/GdsTable";
import { GdsGrouping } from "./gds-grouping/GdsGrouping";
import { useGdsUIContext } from "./GdsUIContext";

export function GdsCard() {
  const gdsUIContext = useGdsUIContext();
  const gdsUIProps = useMemo(() => {
    return {
      ids: gdsUIContext.ids,
      queryParams: gdsUIContext.queryParams,
      setQueryParams: gdsUIContext.setQueryParams,
      newGdButtonClick: gdsUIContext.newGdButtonClick,
      openDeleteGdsDialog: gdsUIContext.openDeleteGdsDialog,
      openEditGdPage: gdsUIContext.openEditGdPage,
      openUpdateGdsStatusDialog:
        gdsUIContext.openUpdateGdsStatusDialog,
      openFetchGdsDialog: gdsUIContext.openFetchGdsDialog,
    };
  }, [gdsUIContext]);

  return (
    <Card>
      <CardHeader title="Dashboard Autoconsumo">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={gdsUIProps.newGdButtonClick}
          >
            Incluir gestão
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <GdsFilter />
        {gdsUIProps.ids.length > 0 && (
          <>
            <GdsGrouping />
          </>
        )}
        <GdsTable />
      </CardBody>
    </Card>
  );
}
