import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new relatorios to the server

export function createRelatorio(relatorio) {
  relatorio.idDistribuidora = parseInt(relatorio.idDistribuidora);
  relatorio.idCliente = parseInt(relatorio.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:relatorio,
  };

  return axios(request);

}
// READ
// Server should return filtered relatorios by productId
export function getAllProductRelatoriosByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getRelatorioById(relatorioId) {
  return axios.get(`${SPECIFICATIONS_URL}/${relatorioId}`);
}

// Server should return sorted/filtered relatorios and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/relatoriosfind/{productId}'. Should be 'api/relatorios/find/{productId}'!!!
export function findRelatorios(queryParams, todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "4";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the relatorio
export function updateRelatorio(id,relatorio) {
  relatorio.IdRelatorio =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: relatorio,
  };
  return axios(request);
}

// DELETE => delete the relatorio
export function deleteRelatorio(relatorioId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${relatorioId}`);
}

export function deleteRelatorios(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaRelatorios(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}

export function marcarComoLidaRelatorio(tipoNotificacaoMenu) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas: '' },
  };

  return axios(request);
}
