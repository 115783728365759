import React from "react";
import NumberFormat from 'react-number-format';


export function EnergiaHtml({
    ...props
}) {

    return (
        <>
            <NumberFormat thousandSeparator={true} suffix={' kWh'}
                displayType={'text'}
                decimalSeparator=","
                thousandSeparator="."
                value={props.value}
            />
        </>
    );
}



export function EnergiaHtmlBoletim({
    ...props
}) {

    return (
        <>
        {/* thousandSeparator={true} */}
            <NumberFormat  suffix={' kWh'}
                displayType={'text'}
                decimalScale={5}
                decimalSeparator=","
                thousandSeparator="."
                value={props.value}
            />
        </>
    );
}

export function EnergiaHtmlBoletimEconomia12Meses({
    ...props
}) {

    return (
        <>
        {/* thousandSeparator={true} */}
            <NumberFormat  
                displayType={'text'}
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                value={props.value}
            />
        </>
    );
}
