import {createSlice} from "@reduxjs/toolkit";
import {aplicarMascaraTelefone,aplicarMascaraCEP} from "../../../../lib/formatarCampos";
const initialReprocessarFaturasIntegracaoState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  reprocessarFaturaIntegracaoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const reprocessarFaturasIntegracaoSlice = createSlice({
  name: "reprocessarFaturasIntegracao",
  initialState: initialReprocessarFaturasIntegracaoState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getReprocessarFaturaIntegracaoById
    reprocessarFaturaIntegracaoFetched: (state, action) => {


      state.actionsLoading = false;
      // APLICAR A MASCARA
      state.reprocessarFaturaIntegracaoForEdit = action.payload.reprocessarFaturaIntegracaoForEdit;
      state.error = null;

    },
    // findReprocessarFaturasIntegracao
    reprocessarFaturasIntegracaoFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createReprocessarFaturaIntegracao
    reprocessarFaturaIntegracaoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.reprocessarFaturaIntegracao);
    },
    // updateReprocessarFaturaIntegracao
    reprocessarFaturaIntegracaoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.reprocessarFaturaIntegracao.id) {
          return action.payload.reprocessarFaturaIntegracao;
        }
        return entity;
      });
    },
    // deleteReprocessarFaturaIntegracao
    reprocessarFaturaIntegracaoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteReprocessarFaturasIntegracao
    reprocessarFaturasIntegracaoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // reprocessarFaturasIntegracaoUpdateState
    reprocessarFaturasIntegracaoStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
