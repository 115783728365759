import React from "react";
import {useField, useFormikContext} from "formik";
import moment from 'moment';
export function DataMesAnoFormatacao(
    cellContent,
    row,
    rowIndex
) {

    return (
        <>
            {moment(cellContent).format('MMM-YYYY')}
        </>
    );
}
