import React, {useEffect, useMemo, useState} from "react";
import ReactExport from "react-export-excel";
import {
    toAbsoluteUrl
} from "../../../../../../_layout/_helpers";
import SVG from "react-inlinesvg";
import * as actions from "../../../_redux/gds/gdsActions";
import { useGdsUIContext } from "../GdsUIContext";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export function GdExcelExport() {

    const [dadoExportacao, setDadoExportacao] = useState([]);
    const gdsUIContext = useGdsUIContext();
    const dispatch = useDispatch();
    useEffect(() => {

        // dispatch(actions.fetchExcelBoletim(gdsUIContext.queryParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gdsUIContext, dispatch]);



   return (<ExcelFile element={ <a className="btn btn-icon btn-light btn-hover-primary">
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/files/csv.svg")}
                                    />
                                            </span>
            </a>}>
                <ExcelSheet data={dadoExportacao} name="GD">
                    <ExcelColumn label="Mês de data" value="name"/>
                    <ExcelColumn label="Nº de UCs" value="amount"/>
                    <ExcelColumn label="Energia Compensada (mWh)" value="sex"/>
                    <ExcelColumn label="Custo geração (R$)"  value={(col) => col.is_married ? "Married" : "Single"}/>
                    <ExcelColumn label="Demanda geração (R$)"  value={(col) => col.is_married ? "Married" : "Single"}/>
                    <ExcelColumn label="Custo Distribuidora (R$)"  value={(col) => col.is_married ? "Married" : "Single"}/>
                    <ExcelColumn label="Total com GD (R$)" value="sex"/>
                    <ExcelColumn label="Total sem GD (R$)" value="sex"/>
                    <ExcelColumn label="Economia (R$)" value="sex"/>
                    <ExcelColumn label="ICMS UCs" value="sex"/>
                    <ExcelColumn label="Economia (%)" value="sex"/>
                    <ExcelColumn label="Tarifa Realizada (R$/MWh)" value="sex"/>
                </ExcelSheet>

            </ExcelFile>)  ;
    }

