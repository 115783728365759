import axios from "axios";
import {config} from "../../../../Config";
export const SPECIFICATIONS_URL = config.url.apiEndPoint+"/notificacao";


// CREATE =>  POST: add a new configuracaos to the server

export function createConfiguracao(configuracao) {
  configuracao.idDistribuidora = parseInt(configuracao.idDistribuidora);
  configuracao.idCliente = parseInt(configuracao.clienteId);
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/incluir`,
    headers: {
      'Content-Type': 'application/json'
    },
    data:configuracao,
  };

  return axios(request);

}
// READ
// Server should return filtered configuracaos by productId
export function getAllProductConfiguracaosByProductId(productId) {
  return axios.get(`${SPECIFICATIONS_URL}?productId=${productId}`);
}

export function getConfiguracaoById(configuracaoId) {
  return axios.get(`${SPECIFICATIONS_URL}/${configuracaoId}`);
}

// Server should return sorted/filtered configuracaos and merge with items from state
// TODO: Change your URL to REAL API, right now URL is 'api/configuracaosfind/{productId}'. Should be 'api/configuracaos/find/{productId}'!!!
export function findConfiguracaos(queryParams, todas) {
  const getUsuario = JSON.parse(localStorage.getItem('@plataforma/user')).idUsuario;
  queryParams.filter.idUsuario = parseInt(getUsuario);
  queryParams.filter.tipoNotificacaoMenu = "9";
  queryParams.filter.todasNotificacaoApenasMinha = todas;
  const request = {
    method: 'POST',
    url: SPECIFICATIONS_URL+`/list`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: queryParams,
  };

  return axios(request);
}

// UPDATE => PUT: update the configuracao
export function updateConfiguracao(id,configuracao) {
  configuracao.IdConfiguracao =parseInt(id);
  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/alterar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: configuracao,
  };
  return axios(request);
}

// DELETE => delete the configuracao
export function deleteConfiguracao(configuracaoId) {
  return axios.delete(`${SPECIFICATIONS_URL}/${configuracaoId}`);
}

export function deleteConfiguracaos(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'I' },
  };

  return axios(request);
}

export function ativaConfiguracaos(ids) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { Ids:ids.join(), Situacao:'A' },
  };

  return axios(request);
}

export function marcarComoLidaConfiguracao(tipoNotificacaoMenu) {

  const request = {
    method: 'PUT',
    url: SPECIFICATIONS_URL+`/status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { tipoNotificacaoMenu: tipoNotificacaoMenu ,Situacao:'L', todas: '' },
  };

  return axios(request);
}
