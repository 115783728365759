import {createSlice} from "@reduxjs/toolkit";
const initialAcessosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  acessoForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const acessosSlice = createSlice({
  name: "acessos",
  initialState: initialAcessosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAcessoById
    acessoFetched: (state, action) => {
      state.actionsLoading = false;
      state.acessoForEdit = action.payload.acessoForEdit;
      state.error = null;
    },
    // findAcessos
    acessosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAcesso
    acessoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.acesso);
    },
    // updateAcesso
    acessoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.acesso.id) {
          return action.payload.acesso;
        }
        return entity;
      });
    },
    // deleteAcesso
    acessoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteAcessos
    acessosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
